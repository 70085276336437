import JSEncrypt from "jsencrypt";
import general from "../general";

const publicKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAu5phb2FQQiuZSlSxI09q4Mc3LIpuUTZP5+EBe480c9/zDkVgPmuIQcBMPbi5NC5yxpYOCUZkLjC8VDzMqDgS89c6hC3qY0btRBxON7gO9pk4ijUEa2xlvnefvYgXMpks13FT5To852iguQbW2k53DHD6Efsvbvh/Nl1kRXhYGPw3XWAQeDNS9xFESpx+9r4KWcm8D/R2fnv5w1P/UvcNrYVDMTGkEmOGnVl96WZBPcHuZRrRcXcbzTE3TMzabQAdqR+FDPRPWpK5F/WUuWWbKL+MS3dvREdOxYPgIfvyPm4ztLp0I+v06/fZvUOembo4Bokj0AusbYKqUkp3s6El11xmHyaRJULh9PYJ9xfte1oihnRpU+HenVUfHBQBy8XBzHkhUsVfKV+WGaARYRQ2efSsTojji8WBXpt63kkkltcgSZJvQ9gPcSTAz3xRkX6tRTEA61AsbT1ua+uJlFWa97P3xHd+SGcOYsOjSbnpB/WGxBMs7dX2FEuevaEEgyJr+d336jhzK/6Mc6eaIKlPPZyZfXUH/KK3YIllDSx2WbAHibTZfetp/76KL+QBLLv2QFGwjWZKxCZ/M2zRvRGL1wLLuw5DAr8LiodEt1YABpVO28YNwf87zpt4XZ7bv4ubNP8NYONSLAB7YqPUnG58UQ8I3Yvw2k3Nmos/Cs/vFasCAwEAAQ==-----END PUBLIC KEY-----`;
const encryptWithPublicKey = (message, publicKey) => {
  // 创建 JSEncrypt 实例
  const encrypt = new JSEncrypt();

  // 设置公钥
  encrypt.setPublicKey(publicKey);

  // 加密消息
  const encrypted = encrypt.encrypt(message);

  return encrypted;
};

const getUuidCallback = async () => {
  const timeCall = new Date().getTime();
  console.log("没有bd_vid激活-----------");

  let objCall = {
    time: timeCall,
    agent: localStorage.getItem("agent"),
  };
  let strCall = btoa(JSON.stringify(objCall));

  // 调用加密函数
  const encryptedMessageCall = encryptWithPublicKey(strCall, publicKey);

  await general.axios
    .post("/tgm/is_callback", {
      time: timeCall,
      agent: localStorage.getItem("agent"),
      sign: encryptedMessageCall,
    })
    .then(async (res) => {
      console.log(res);

      if (res?.data?.data?.is_bind) {
        const time = new Date().getTime();
        console.log("没有bd_vid激活-----------");

        let obj = {
          account_type: "baidu_xs",
          package_name: "com.xt.xuantian",
          os_type: "ios",
          MODEL: "2304FPN6DC",
          time,
          vid: localStorage.getItem("bd_vid"),
          agent: localStorage.getItem("agent"),
        };
        let str = btoa(JSON.stringify(obj));

        // 调用加密函数
        const encryptedMessage = encryptWithPublicKey(str, publicKey);

        await general.axios
          .post("/tgm/impute_bind", {
            account_type: "baidu_xs",
            package_name: "com.xt.xuantian",
            os_type: "ios",
            MODEL: "2304FPN6DC",
            time,
            vid: localStorage.getItem("bd_vid"),
            sign: encryptedMessage,
            agent: localStorage.getItem("agent"),
          })
          .then((res) => {
            console.log(res);

            if (res?.data?.data?.uuid) {
              localStorage.setItem("tgm_uuid", res.data.data.uuid);
            }
          })
          .catch((error) => console.log("media-point " + error));
      } else {
        localStorage.setItem("tgm_uuid", "");
      }
    })
    .catch((error) => console.log("media-point " + error));
};

const behaviorTrigger = (type, params) => {
  let time = new Date().getTime();
  let objBind = {
    type,
    uuid: localStorage.getItem("tgm_uuid"),
    time,
    ...params,
  };

  let strBind = btoa(JSON.stringify(objBind));

  // 调用加密函数
  const encryptedBindMessage = encryptWithPublicKey(strBind, publicKey);

  general.axios
    .post("/tgm/trigger/warning", {
      type,
      uuid: localStorage.getItem("tgm_uuid"),
      time,
      ...params,
      sign: encryptedBindMessage,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log("游戏详情访问回传--- " + error));
};

export { getUuidCallback, behaviorTrigger };
