import { prod, url } from "../../config";
import { Link, useHistory } from "react-router-dom";
import { PhoneNumberServer } from "aliyun_numberauthsdk_web";
import React, { useEffect, useRef, useState } from "react";
import general from "../../general";
import { useInterval, useLocalStorageState } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import JSEncrypt from "jsencrypt";
import useUrlState from "@ahooksjs/use-url-state";

import { behaviorTrigger } from "../../utils/trigger";

const phoneNumberServer = new PhoneNumberServer();

// 手机号、验证码登录
export default (props) => {
  const history = useHistory();
  const [params] = useUrlState();
  const [app] = useActiveState("app");
  const { aplus_queue } = window;
  const [agreement, setAgreement] = useLocalStorageState("agreement", {
    defaultValue: true,
  });
  const [loging, setLoging] = useState();
  const phoneRef = useRef(null);
  const [phone, setPhone] = useLocalStorageState("phone", {
    defaultValue: !prod ? "13227737527" : "",
  });
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);

  const encryptWithPublicKey = (message, publicKey) => {
    // 创建 JSEncrypt 实例
    const encrypt = new JSEncrypt();

    // 设置公钥
    encrypt.setPublicKey(publicKey);

    // 加密消息
    const encrypted = encrypt.encrypt(message);

    return encrypted;
  };

  // PC模式下 - 手机号、验证码输入后回车  调用登录
  const login = () => {
    if (!phone) {
      Dialog.error("请输入手机号");
      return;
    }
    if (!/^\d{11}$/.test(phone)) {
      Dialog.error("手机号码格式不正确");
      return;
    }
    if (!agreement) {
      Dialog.error("请阅读下方的协议");
      return;
    }
    if (codeLoging) {
      codeLogin();
      return;
    }
    setLoging(true);
    Dialog.loading();
    let uri = URI();
    general.axios
      .post("/getLoginToken", {
        url: uri.protocol() + "://" + uri.host() + "/",
        origin: uri.protocol() + "://" + uri.host(),
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: {
            tokenInfo: { accessToken, jwtToken },
          },
        } = res.data;
        if (!status.succeed) {
          setLoging(false);
          Dialog.error(status.error_desc);
          return;
        }
        phoneNumberServer.checkAuthAvailable({
          phoneNumber: phone,
          accessToken: accessToken,
          jwtToken: jwtToken,
          success: (res) => {
            console.log(res);
            Dialog.close();
            Dialog.loading();
            phoneNumberServer.getVerifyToken({
              success: (res) => {
                console.log(res);
                Dialog.close();
                Dialog.loading();
                general.axios
                  .post("/oneClickLogin", {
                    phone_number: phone,
                    sp_token: res.spToken,
                  })
                  .finally(() => {
                    setLoging(false);
                    Dialog.close();
                  })
                  .then((res) => {
                    let { status, data } = res.data;
                    if (!status.succeed) {
                      console.error(status);
                      setLoging(false);
                      // Dialog.error(status.error_desc);
                      setCodeLoging(true);
                      return;
                    }
                    let users = data.users;
                    if (users.length === 1) {
                      if (!users[0].isSetPassword) {
                        setIsPassword(true);
                        setUser(users[0]);
                        return;
                      }
                      localStorage.setItem("token", users[0].token);
                      localStorage.setItem("user_id", users[0].id);
                      // localStorage.setItem("username", users[0].username);
                      aplus_queue.push({
                        action: "aplus.record",
                        arguments: [
                          "login",
                          "login",
                          {
                            type: "oneClickLogin",
                            agent: localStorage.getItem("agent"),
                            username: users[0].username,
                          },
                        ],
                      });

                      // 是老用户  并且为新人福利登录 返回首页
                      if (users[0].is_new_user === 0 && params.back) {
                        Dialog.info("登录成功", () => history.replace("/"));
                      } else {
                        Dialog.info("登录成功", () => general.goBack(true));
                      }

                      getUserInfo();
                      getPopup();
                    } else {
                      setUsers(users);
                    }
                  })
                  .catch((err) => {
                    axios.post(`${url}/log`, {
                      type: "oneClickLogin",
                      err: err,
                    });
                    console.error(err);
                    Dialog.error("oneClickLogin " + err.message);
                  });
              },
              error: (err) => {
                axios.post(`${url}/log`, { type: "getVerifyToken", err: err });
                console.error(res);
                setLoging(false);
                // Dialog.error(res.msg);
                Dialog.close();
                setCodeLoging(true);
              },
            });
          },
          error: (err) => {
            axios.post(`${url}/log`, { type: "checkAuthAvailable", err: err });
            console.error(res);
            setLoging(false);
            // Dialog.error(res.msg);
            Dialog.close();
            setCodeLoging(true);
          },
        });
      })
      .catch((err) => {
        axios.post(`${url}/log`, { type: "getLoginToken", err: err });
        console.error(err);
        setLoging(false);
        // Dialog.error(err.message);
        Dialog.close();
        setCodeLoging(true);
      });
  };

  // 下拉框选择用户/手机号登录设置密码后
  const finalLogin = (user) => {
    setUser(user);
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios
      .post("/finalCellularLogin", {
        username: user.username,
        token: user.token,
        password,
      })
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          setLoging(false);
          Dialog.error(status.error_desc);
          return;
        }
        localStorage.setItem("token", data.token);
        localStorage.setItem("user_id", data.id);
        // localStorage.setItem("username", data.username);
        aplus_queue.push({
          action: "aplus.record",
          arguments: [
            "login",
            "login",
            {
              type: "oneClickLogin",
              agent: localStorage.getItem("agent"),
              username: data.username,
            },
          ],
        });

        // 是老用户  并且为新人福利登录 返回首页
        if (data.is_new_user === 0 && params.back) {
          Dialog.info("登录成功", () => history.replace("/"));
        } else {
          Dialog.info("登录成功", () => general.goBack(true));
        }

        getUserInfo();
        getPopup();
      })
      .catch((err) => {
        axios.post(`${url}/log`, { type: "finalCellularLogin", err: err });
        Dialog.error("finalCellularLogin " + err.message);
      });
  };
  /*useEffect(() => {
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }, []);*/

  const [codeLoging, setCodeLoging] = useState(true);
  useEffect(() => {
    /*if (codeLoging) {
      codeGet();
      /!*if (codeRef.current) {
        codeRef.current.focus();
      }*!/
    }*/
  }, [codeLoging]);
  const codeRef = useRef();
  const [code, setCode] = useState("");
  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const codeGet = () => {
    if (codIng || codeTime) {
      return;
    }
    setCodIng(true);
    Dialog.loading();
    general.axios
      .post("/base/tool/getVerifyCode", {
        mobile: phone,
        code: "code",
        type: "08",
      })
      .finally(() => {
        setCodIng(false);
        Dialog.close();
      })
      .then((res) => {
        console.log("手机号登录", res);
        if (res.data) {
          let { status } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setCodeTime(60);
          }
        } else {
          Dialog.error("获取验证码失败，请重试");
        }
      })
      .catch((err) => {
        axios.post(`${url}/log`, { type: "getVerifyCode", err: err });
        Dialog.error("getVerifyCode " + err.message);
      });
  };

  let timer = null;
  const codeLogin = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      loginCode();
    }, 500);
  };

  // 手机号验证码输入完成  点击按钮登录
  const loginCode = () => {
    if (loging) {
      return;
    }
    if (!phone) {
      Dialog.error("请输入手机号");
      return;
    }
    if (!/^\d{11}$/.test(phone)) {
      Dialog.error("手机号码格式不正确");
      return;
    }
    if (!agreement) {
      Dialog.error("请阅读下方的协议");
      return;
    }
    if (!code) {
      Dialog.error("请输入验证码");
      return;
    }
    setLoging(true);
    Dialog.loading();
    // general.axios
    //   .post("/verificaCodeLogin", { phone_number: phone, code, password })
    //   .finally(() => {
    //     setLoging(false);
    //     Dialog.close();
    //   })

    general.axios
      .post("/user/user/verificaCodeLoginGrpc", {
        phone_number: phone,
        code,
        password,
      })
      .finally(() => {
        setLoging(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          setLoging(false);
          Dialog.error(status.error_desc);
          return;
        }

        let users = data.users;
        if (users.length === 1) {
          if (data.type == "register" && localStorage.getItem("tgm_uuid")) {
            behaviorTrigger("register", {
              username: users[0].username,
            });
          }

          if (!users[0].isSetPassword) {
            setIsPassword(true);
            setUser(users[0]);
            return;
          }
          localStorage.setItem("token", users[0].token);
          localStorage.setItem("user_id", users[0].id);
          // localStorage.setItem("username", users[0].username);
          aplus_queue.push({
            action: "aplus.record",
            arguments: [
              "login",
              "login",
              {
                type: "code",
                agent: localStorage.getItem("agent"),
                username: users[0].username,
              },
            ],
          });

          // 是老用户  并且为新人福利登录 返回首页
          if (users[0].is_new_user === 0 && params.back) {
            Dialog.info("登录成功", () => history.replace("/"));
          } else {
            Dialog.info("登录成功", () => general.goBack(true));
          }

          getUserInfo();
          getPopup();
        } else {
          setUsers(users);
        }
      })
      .catch((err) => {
        axios.post(`${url}/log`, { type: "verificaCodeLogin", err: err });
        Dialog.error("verificaCodeLogin " + err.message);
      });
  };

  const getUserInfo = () => {
    general.axios
      .post("/user/user/getMemberInfo")
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          if (status.error_code == 1016) {
            localStorage.removeItem("user_id");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("token");
          } else {
            Dialog.error(status.error_desc);
          }
        } else {
          localStorage.setItem("username", data.member_info.member_name);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  const getPopup = () => {
    general.axios.post("/movable/popup/popup").then(({ data: resData }) => {
      console.log("右下角悬浮球--------", resData);
      let { status, data } = resData;
      if (status.succeed) {
        sessionStorage.setItem(
          "popupData",
          JSON.stringify({
            ...data,
            route: data?.jump_value,
          })
        );
      }
    });
  };

  useEffect(() => {
    code.length === 6 && codeLogin();
  }, [code]);

  const [isPassword, setIsPassword] = useState();
  const [password, setPassword] = useState();

  const [focus, setFocus] = useState("phone");

  let clickCount = 0;

  return (
    <div className="account-phonelogin">
      <div className="phonelogin-body">
        <div className="logo">
          <img src={app.applogo} alt="" />
        </div>

        <div className="body">
          <div className="input">
            <input
              name="phone"
              type="tel"
              value={phone}
              placeholder="请输入手机号"
              onKeyUp={(e) => e.keyCode === 13 && login()}
              onChange={(e) => setPhone(e.target.value)}
              autoFocus
              ref={phoneRef}
              onFocus={() => setFocus("phone")}
              onMouseDown={() => {
                clickCount++;
                if (clickCount > 1) {
                  Dialog.info(
                    "若遇到iOS系统键盘问题，请重启手机或我的->联系客服"
                  );
                  clickCount = 0;
                }
              }}
            />
            {focus === "phone" && phone ? (
              <CloseOutlined className="close" onClick={() => setPhone("")} />
            ) : null}
          </div>
          {codeLoging ? (
            <div className="code">
              <input
                name="code"
                type="tel"
                value={code}
                placeholder="请输入验证码"
                onKeyUp={(e) => e.keyCode === 13 && login()}
                onChange={(e) => setCode(e.target.value.slice(0, 6))}
                autoFocus
                autoComplete="off"
                ref={codeRef}
                onFocus={() => setFocus("code")}
                onMouseDown={() => {
                  clickCount++;
                  if (clickCount > 1) {
                    Dialog.info(
                      "若遇到iOS系统键盘问题，请重启手机或我的->联系客服"
                    );
                    clickCount = 0;
                  }
                }}
              />
              {focus === "code" && code ? (
                <CloseOutlined className="clear" onClick={() => setCode("")} />
              ) : null}
              <div
                className={"code-btn " + (codIng || codeTime ? "disable" : "")}
                onClick={codeGet}
              >
                {codIng
                  ? "发送中"
                  : codeTime
                  ? codeTime + "秒后重发"
                  : "发送验证码"}
              </div>
            </div>
          ) : null}
          <div className="tips">未注册的用户可直接注册登录</div>
        </div>
        <div className={"btn " + (loging ? "disable" : "")} onClick={codeLogin}>
          登录
        </div>
        <Link
          to={`/login${params.back ? "?back=1" : ""}`}
          className="btn-phone"
        >
          更多登录&gt;
        </Link>
      </div>
      {users.length > 0 ? (
        <div className="phonelogin-users">
          <div className="mask" onClick={() => setUsers([])} />
          <div className="body">
            <div className="title">选择用户</div>
            <div className="users">
              {users.map((user, index) => (
                <div
                  key={index}
                  className="user"
                  onClick={() => finalLogin(user)}
                >
                  {user.username}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {isPassword ? (
        <div className="phonelogin-password">
          <div className="body">
            <div className="title">请设置密码</div>
            <input
              type="password"
              placeholder="输入密码"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onMouseDown={() => {
                clickCount++;
                if (clickCount > 1) {
                  Dialog.info(
                    "若遇到iOS系统键盘问题，请重启手机或我的->联系客服"
                  );
                  clickCount = 0;
                }
              }}
            />
            <div
              className={"btn " + (!password ? "disable" : "")}
              onClick={() => {
                if (password) {
                  if (password.length < 6 || password.length > 16) {
                    Dialog.error("密码必须是6-16位");
                    return;
                  }
                  finalLogin(user);
                }
              }}
            >
              提交
            </div>
          </div>
        </div>
      ) : null}
      <div className="account-agreement">
        <label>
          <span
            className={"agreement" + (agreement ? " checked" : "")}
            onClick={() => setAgreement(true)}
          />
          登录即代表您同意
          <Link to={"/iframe?url=" + url + "/userAgreement&title=用户协议"}>
            用户协议
          </Link>
          、
          <Link to={"/iframe?url=" + url + "/privacyPolicy&title=隐私政策"}>
            隐私政策
          </Link>
          <br />
          接受免除或者限制责任、诉讼管辖约定等粗体标示条款
        </label>
      </div>
    </div>
  );
};
