import React from "react";
import Index from "./view/index/Index";
import Game from "./view/game/Index";
// import Task from "./view/task/Index";
import Welfare from "./view/welfare/Index";
import Service from "./view/service/Service";
import User from "./view/user/Index";
import TodayStartGame from "./view/game/TodayStart";
import ReserveGame from "./view/game/Reserve";
import GameGame from "./view/game/Game";
import GameVoucher from "./view/game/Voucher";
import GameGift from "./view/game/Gift";
import GameGiftDetail from "./view/game/GiftDetail";
import GameArticleGroup from "./view/game/article/Groups";
import GameArticle from "./view/game/article/Articles";
import Article from "./view/article/Index";
import GameProject from "./view/game/Project";
import IndexProject from "./view/game/IndexProject";
import Gifts from "./view/gift/Gifts";
import GiftSearch from "./view/gift/Search";
import Voucher from "./view/voucher/Index";
import VoucherLateplay from "./view/voucher/Lateplay";
import Server from "./view/server/Index";
import Credit from "./view/credit/Index";
import CreditGift from "./view/credit/Gift";
import Search from "./view/search/Index";
import Faq from "./view/Faq";
import Trade from "./view/trade/Index";
import TradeDetail from "./view/trade/Detail";
import TradeNotice from "./view/trade/Notice";
import TradeSubmit from "./view/trade/Submit";
import TradeRecord from "./view/trade/Record";
import ArticleDetail from "./view/article/Detail";
import CommentDetail from "./view/comment/Detail";
import CommentSubmit from "./view/comment/Submit";
import QAQuestion from "./view/qa/Question";
import QAAnswer from "./view/qa/Answer";
import Img from "./view/general/Img";
import Iframe from "./view/general/Iframe";
import GameSelect from "./view/game/Select";
import Feedback from "./view/feedback/Index";
import Forget from "./view/account/Forget";
import Register from "./view/account/Register";
import Login from "./view/account/Login";
import PhoneLogin from "./view/account/PhoneLogin";
import Phone from "./view/account/Phone";
import Video from "./view/general/Video";
import TaskSign from "./view/task/Sign";
import TaskNovice from "./view/task/Novice";
import TaskDay from "./view/task/Day";
import Messages from "./view/message/Messages";
import Message from "./view/message/Message";
import Rebate from "./view/rebate/Index";
import RebateGuide from "./view/rebate/Guide";
import RebateSubmit from "./view/rebate/Submit";
import RebateSuccess from "./view/rebate/Success";
import RebateDetail from "./view/rebate/Detail";
import UserProfile from "./view/user/Profile";
import UserSetting from "./view/user/Setting";
import UserNickname from "./view/user/Nickname";
import UserQQ from "./view/user/QQ";
import UserPhone from "./view/user/Phone";
import UserPhoneRemove from "./view/user/PhoneRemove";
import UserPhoneVerify from "./view/user/PhoneVerify";
import UserPhoneReplace from "./view/user/PhoneReplace";
import UserRealName from "./view/user/Realname";
import UserPassword from "./view/user/Password";
import UserPasswordPhone from "./view/user/PasswordPhone";
import PasswordForce from "./view/user/PasswordForce";
import UserCredit from "./view/user/Credit";
import UserVoucher from "./view/user/Voucher";
import UserGame from "./view/user/game/Index";
import UserFavorite from "./view/user/game/Favorite";
import UserReserveGame from "./view/user/game/ReserveGame";
import UserLikeGame from "./view/user/game/LikeGame";
import UserGift from "./view/user/Gift";
import Invite from "./view/invite/Index";
import Recovery from "./view/recovery/Index";
import RecoveryDetail from "./view/recovery/Detail";
import RecoveryRecord from "./view/recovery/Record";
import UserBill from "./view/user/Bill";
import Ptb from "./view/ptb/Index";
import PtbRecord from "./view/ptb/Record";
import Test from "./view/Test";
import VipWatch from "./view/game/detail/VipWatch";
import HangUp from "./view/game/hangUp/Index";
import DetailTrade from "./view/game/detail/Trade";
import ServiceDetail from "./view/service/Detail";
import Desktop from "./view/game/cloud/Desktop1";
import Question from "./view/game/detail/question";

const GameCloud = React.lazy(() => import("./view/game/cloud/Index"));

export const screenRouter = [
  {
    id: "index",
    path: "/",
    component: Index,
    title: "首页",
  },
  {
    id: "game",
    path: "/screen-game",
    component: Game,
    title: "分类",
  },
  /*{
    id: 'task',
    path: '/screen-task',
    component: Task,
    title: '积分',
    user: true,
  },*/
  {
    id: "welfare",
    path: "/screen-welfare",
    component: Welfare,
    title: "福利中心",
    user: true,
  },
  {
    id: "service",
    path: "/screen-trade",
    component: Trade,
    title: "交易",
  },
  /*{
    id: 'service',
    path: '/screen-service',
    component: Service,
    title: '客服',
  },*/
  {
    id: "user",
    path: "/screen-user",
    component: User,
    title: "我的",
  },
];

export const navigationRouter = [
  {
    path: "/question",
    component: Question,
    title: "常见问题",
  },
  {
    path: "/desktop",
    component: Desktop,
    title: "云桌面",
  },
  {
    path: "/game/hangup",
    component: HangUp,
    title: "云挂机",
  },
  {
    path: "/game/vip",
    component: VipWatch,
    title: "vip表",
  },

  {
    path: "/game/today-start",
    component: TodayStartGame,
    title: "新游预约",
  },
  {
    path: "/game/reserve",
    component: ReserveGame,
    title: "新游预约",
  },
  {
    path: "/game",
    component: GameGame,
    title: "游戏详情",
    topHidden: true,
    invasion: true,
    statusBarBackground: false,
  },
  {
    path: "/game/voucher",
    component: GameVoucher,
    title: "代金券",
  },

  {
    path: "/game/trade",
    component: DetailTrade,
    title: "交易",
  },
  {
    path: "/game/gift",
    component: GameGift,
    title: "礼包",
  },
  {
    path: "/game/giftDetail",
    component: GameGiftDetail,
    title: "礼包",
  },
  {
    path: "/game/articleGroup",
    component: GameArticleGroup,
    title: "活动攻略",
  },
  {
    path: "/game/article",
    component: GameArticle,
    title: "活动攻略",
  },
  {
    path: "/article",
    component: Article,
    title: "活动中心",
  },
  {
    path: "/game/project",
    component: GameProject,
  },
  {
    path: "/game/indexProject",
    component: IndexProject,
    title: "专题详情",
    // invasion: true,
    // statusBarBackground: false,
  },
  {
    path: "/trade",
    component: Trade,
    title: "账号交易",
  },
  {
    path: "/trade/detail",
    component: TradeDetail,
    title: "商品详情",
  },
  {
    path: "/trade/notice",
    component: TradeNotice,
    title: "交易须知",
  },
  {
    path: "/trade/submit",
    component: TradeSubmit,
    title: "我要卖号",
  },
  {
    path: "/trade/record",
    component: TradeRecord,
    title: "交易记录",
  },
  {
    path: "/article/detail",
    component: ArticleDetail,
    title: "活动详情",
  },
  {
    path: "/comment",
    component: CommentDetail,
    title: "评论详情",
  },
  {
    path: "/comment/submit",
    component: CommentSubmit,
    title: "发布评论",
  },
  {
    path: "/question",
    component: QAQuestion,
    title: "问答",
  },
  {
    path: "/answer",
    component: QAAnswer,
    title: "问答详情",
  },
  {
    path: "/search",
    component: Search,
    title: "搜索",
  },
  {
    path: "/faq",
    component: Faq,
    title: "常见问题",
  },
  {
    path: "/img",
    component: Img,
    invasion: true,
    theme: "bright",
    backColor: "#fff",
  },
  {
    path: "/iframe",
    component: Iframe,
  },
  {
    path: "/video",
    component: Video,
    invasion: true,
  },
  {
    path: "/game/select",
    component: GameSelect,
    title: "选择游戏",
  },
  {
    path: "/feedback",
    component: Feedback,
    title: "投诉反馈",
  },
  {
    path: "/login",
    component: Login,
    invasion: true,
    theme: "dark",
  },
  {
    path: "/phonelogin",
    component: PhoneLogin,
    title: "一键登录",
    invasion: true,
    theme: "dark",
  },
  {
    path: "/register",
    component: Register,
    invasion: true,
    theme: "dark",
  },
  {
    path: "/phone",
    component: Phone,
    title: "绑定手机",
  },
  {
    path: "/phone",
    component: Phone,
    title: "绑定手机",
  },
  {
    path: "/forget",
    component: Forget,
    title: "忘记密码",
  },
  {
    path: "/task/sign",
    component: TaskSign,
    title: "每日签到",
    invasion: true,
    color: "255,152,0",
  },
  {
    path: "/task/novice",
    component: TaskNovice,
    title: "新手任务",
    invasion: true,
    color: "243,82,7",
    statusBarBackground: false,
  },
  {
    path: "/task/day",
    component: TaskDay,
    title: "每日任务",
    invasion: true,
    color: "243,82,7",
    statusBarBackground: false,
  },
  /*{
    path: '/task/success',
    component: TaskSuccess,
    title: '成就任务',
    invasion: true,
    color: '255,152,0',
  },*/
  {
    path: "/gift",
    component: Gifts,
    title: "礼包中心",
  },
  {
    path: "/gift/search",
    component: GiftSearch,
    title: "礼包搜索",
    className: "nav-view-gift-search",
  },
  {
    path: "/voucher",
    component: Voucher,
    title: "领券中心",
    background: "#f8f8f8",
  },
  {
    path: "/voucher/lateplay",
    component: VoucherLateplay,
    title: "大家都在玩",
    background: "#f8f8f8",
  },
  {
    path: "/server",
    component: Server,
    title: "开服表",
  },
  {
    path: "/credit",
    component: Credit,
    title: "积分商城",
  },
  {
    path: "/credit/gift",
    component: CreditGift,
    title: "礼包&礼品",
  },
  {
    path: "/messages",
    component: Messages,
    title: "消息中心",
  },
  {
    path: "/message",
    component: Message,
    title: "消息详情",
  },
  {
    path: "/user",
    component: UserProfile,
    title: "个人中心",
  },
  {
    path: "/setting",
    component: UserSetting,
    title: "设置",
  },
  {
    path: "/user/nickname",
    component: UserNickname,
    title: "昵称",
  },
  {
    path: "/user/qq",
    component: UserQQ,
    title: "QQ",
  },
  {
    path: "/user/phone",
    component: UserPhone,
    title: "绑定手机号",
  },
  {
    path: "/user/phone-remove",
    component: UserPhoneRemove,
    title: "解绑手机号",
  },
  {
    path: "/user/phone-verify",
    component: UserPhoneVerify,
    title: "验证原手机号",
  },
  {
    path: "/user/phone-replace",
    component: UserPhoneReplace,
    title: "绑定新手机号",
  },
  {
    path: "/user/realname",
    component: UserRealName,
    title: "实名认证",
  },
  {
    path: "/user/password",
    component: UserPassword,
    title: "登录密码",
  },
  {
    path: "/user/password-phone",
    component: UserPasswordPhone,
    title: "登录密码 - 验证手机号",
  },
  {
    path: "/user/password-force",
    component: PasswordForce,
    title: "设置密码",
    back: false,
  },
  {
    path: "/rebate",
    component: Rebate,
    title: "返利中心",
  },
  {
    path: "/rebate/guide",
    component: RebateGuide,
    title: "返利指南",
  },
  {
    path: "/rebate/submit",
    component: RebateSubmit,
    title: "申请详情",
  },
  {
    path: "/rebate/success",
    component: RebateSuccess,
    title: "提交成功",
  },
  {
    path: "/rebate/detail",
    component: RebateDetail,
    title: "申请详情",
    invasion: true,
    color: "255,152,0",
  },
  {
    path: "/user/credit",
    component: UserCredit,
    title: "积分记录",
  },
  {
    path: "/user/voucher",
    component: UserVoucher,
    title: "代金券",
  },
  {
    path: "/user/game",
    component: UserGame,
    title: "我的游戏",
  },
  {
    path: "/user/favorite",
    component: UserFavorite,
    title: "我的收藏",
  },
  {
    path: "/user/reserve",
    component: UserReserveGame,
    title: "我的预约",
  },
  {
    path: "/user/likeGame",
    component: UserLikeGame,
    title: "足迹",
  },
  {
    path: "/user/gift",
    component: UserGift,
    title: "我的礼包",
  },
  {
    path: "/invite",
    component: Invite,
    title: "邀请好友",
  },
  {
    path: "/recovery",
    component: Recovery,
    title: "小号回收",
  },
  {
    path: "/recovery/select",
    component: RecoveryDetail,
    title: "选择小号",
  },
  {
    path: "/recovery/record",
    component: RecoveryRecord,
    title: "回收记录",
  },
  {
    path: "/user/bill",
    component: UserBill,
    title: "我的账单",
  },
  {
    path: "/service",
    component: Service,
    title: "联系客服",
  },
  {
    path: "/service/detail",
    component: ServiceDetail,
    title: "联系客服",
  },

  {
    path: "/ptb",
    component: Ptb,
    title: "我的钱包",
  },
  {
    path: "/ptb/record",
    component: PtbRecord,
    title: "充值记录",
  },
  {
    path: "/test",
    component: Test,
    title: "test",
  },
  {
    path: "/game/cloud",
    component: GameCloud,
    title: "云玩",
  },
];
