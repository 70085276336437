import React, { useEffect, useRef, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { useLocalStorageState, useMount, useScroll } from "ahooks";
import general, { redux, spm, imgPlaceholder } from "../../general";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useHistory } from "react-router-dom";
import { NavLink } from "../../lib/Navigation";
import dayjs from "dayjs";
import useActiveState from "../../lib/useActiveState";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import _useLocalStorageState from "../../lib/useLocalStorageState";
import urlMatching from "../../utils/urlMatching";
import Act from "./Act";
import { sysUrl } from "../../config";
// import Msg from "./Msg";

// 首页不同区域游戏列表展示
const swipers = [];

const View = (props) => {
  const [config] = useActiveState("config");
  let history = useHistory();
  const [app] = useActiveState("app");
  const [user] = useActiveState("user");
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [banners, setBanners] = _useLocalStorageState("good-banner", {
    defaultValue: [],
  });
  const [popularGame, setPopularGame] = _useLocalStorageState(
    "good-popularGame",
    { defaultValue: [] }
  );
  const [menus, setMenus] = _useLocalStorageState("good-menu", {
    defaultValue: [],
  });
  const [gameDatas, setGameDatas] = _useLocalStorageState("good-data", {
    defaultValue: [],
  });
  const [selectiveSlassification, setSelectiveSlassification] = useState([]);
  const [badge, setBadge] = useLocalStorageState(
    "good-badge-" + dayjs().format("YYYY-MM-DD"),
    { defaultValue: {} }
  );
  const [showBanner, setShowBanner] = useState(false);
  const [advertis, setAdvertis] = useState(null);
  const [actActive, setActActive] = useState(false);

  const load = () => {
    if (loading) {
      return;
    }
    setActActive(true);
    setLoading(true);
    // Dialog.loading();
    // /base/newindex/getHomeGameList
    general.axios
      .post("/base/apphome/getAppHome", {
        device: "web",
        channel: localStorage.getItem("agent") || "x7z1",
      })
      .finally(() => {
        setLoading(false);
        // Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("首页整体数据----------------", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);

        // 顶部搜索框-自定义标签 数据
        data.tag && props.setExtra(data.tag);

        // 顶部banner数据
        setBanners(data.banner);
        setShowBanner(true);

        // 顶部banner下的-常用功能入口 数据
        // 加入  我的游戏  功能入口
        //  data.entrance.length
        data.entrance.splice(4, 0, {
          icon: "https://filestorage.9917.cn/ad/newbanner/1687335337.png",
          id: "233",
          is_verification_login: 1,
          name: "我的游戏",
          param: "",
          type: "userGame",
        });
        setMenus(data.entrance);

        // 胶囊
        setPopularGame(data.capsule);

        // 游戏及专题列表
        setGameDatas(data.list);

        // 精选分类
        setSelectiveSlassification(data.itemize);

        setActActive(false);

        // 首页广告
        if (data.ad) {
          props.setAdvertising(data.ad);
          setAdvertis(data.ad);
        }
      })
      .catch((err) => {
        console.log(err);
        Dialog.error(err.message);
      });
  };

  useEffect(() => {
    // && !init

    if (props.active) {
      load();
    }
  }, [props.active]);

  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    swiper && swiper.update();
  }, [props.active, swiper, banners]);

  const menuRef = useRef(null);
  useEffect(() => {
    if (menuRef.current && props.active && !init) {
      setTimeout(() => {
        $(menuRef.current).animate(
          { scrollLeft: menuRef.current?.offsetWidth || 0 },
          1000,
          null,
          () => {
            $(menuRef.current).animate({ scrollLeft: 0 }, 1000);
          }
        );
      }, 250);
    }
  }, [props.active, init]);
  const menuScroll = useScroll(menuRef);
  const scrollbarRef = useRef(null);
  useEffect(() => {
    if (menuRef.current && menuScroll && scrollbarRef.current) {
      $(scrollbarRef.current).css({
        left:
          ((menuScroll.left + $(menuRef.current).width() / 2) /
            menuRef.current.scrollWidth) *
            100 +
          "%",
      });
      $(scrollbarRef.current).css({
        // width:
        //   ($(menuRef.current).width() / menuRef.current.scrollWidth) * 100 +
        //   "%",
        width: "50%",
      });
    }
  }, [menuScroll]);

  useEffect(() => {
    props.active && swipers.forEach((swiper) => swiper.update());
  }, [props.active, gameDatas]);

  const [firstActivity, setFirstActivity] = useState(false);

  const [activity, setActivity] = useLocalStorageState("activity");
  const [activityShow, setActivityShow] = useState();
  const [activityTime, setActivityTime] = useLocalStorageState(
    "activityed-" + dayjs().format("YYYY-MM-DD")
  );
  useMount(() => {
    general.axios
      .post("/base/activity/getActivityNew")
      .then((res) => {
        let { status, data } = res.data;
        console.log("新人福利弹窗-------", data);
        if (status.succeed) {
          setActivity(data || null);
        }
      })
      .catch((err) => {
        Dialog.error(
          <div style={{ textAlign: "center" }}>
            <div>请求失败，请稍后重试~</div>
            <div style={{ fontSize: "0.2rem" }}>{err.message}</div>
          </div>
        );
      });
  });

  const [appUdid, setAppUdid] = useState(false);
  let [agent] = useActiveState("agent");
  let [key] = useActiveState("key");

  const [first, setFirst] = useLocalStorageState("first", {
    defaultValue: true,
  });
  const [alert, setAlert] = useState();

  const [guide] = useActiveState("guide");

  return (
    <div className="index-good">
      {/* 顶部banner */}
      {banners && showBanner ? (
        <>
          <div className="index-banner">
            <Swiper
              loop={true}
              autoplay={{ disableOnInteraction: false }}
              pagination={{ clickable: true }}
            >
              {banners?.map((item, index) => (
                <SwiperSlide key={index} className="index-banner-slide">
                  <NavLink
                    user={item.if_check == 1 ? true : false}
                    iframe={
                      item.type == "outer_h5" ? { title: item.text } : null
                    }
                    to={urlMatching(item)}
                    href={item.type == "outer_contact" ? item.param : ""}
                  >
                    <img src={item.img} className="index-banner-img" alt="" />
                    {item.type === "game_newinfo" ? (
                      <div className="index-banner-font">
                        <div className="index-banner-font-flex">
                          <span className="index-banner-font-name">
                            {item.details.game_name_main}
                          </span>
                          {item.details.game_name_branch ? (
                            <div className="index-banner-font-topup">
                              {item.details.game_name_branch}
                            </div>
                          ) : null}
                        </div>
                        <div className="index-banner-font-flex index-banner-font-label">
                          {item.details.version}
                          {item.details.played_count &&
                          item.details.played_count > 0 ? (
                            <>
                              <div className="line"></div>
                              <span>{item.details.played_count}万人在玩</span>
                            </>
                          ) : null}
                        </div>
                        <div className="index-banner-font-flex index-banner-font-welfare">
                          {item.details.benefits
                            ? item.details.benefits
                                .split("+")
                                .map((item, index) => (
                                  <div className="welfare" key={index}>
                                    {item}
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                    ) : null}
                  </NavLink>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      ) : (
        <div className="game-skeleton">
          <div className="a" />
          <div className="b">
            <img src={imgPlaceholder.default} />
          </div>
        </div>
      )}
      {/* <Msg /> */}
      {/* 常用功能入口 */}
      <div className="index-menus">
        <div className="container" ref={menuRef}>
          {menus.map((menu) => (
            <NavLink
              key={menu.id}
              className={`menu ${
                menu.id == 2 && !badge[menu.id] ? "badge" : ""
              } ${
                menu.id == 233 &&
                config.udid_usable &&
                config.udid_usable != localStorage.getItem("udid_usable")
                  ? "badge"
                  : ""
              }`}
              to={(() => {
                if (menu.type === "h5") {
                  return menu.param;
                } else if (menu.type === "couponCore") {
                  return "/voucher";
                } else if (menu.type === "newGame") {
                  return "/game/today-start";
                } else if (menu.type === "special") {
                  return `/game/indexProject?id=${menu.param}`;
                } else if (menu.type === "integral") {
                  return "/credit";
                } else if (menu.type === "accountTransaction") {
                  return "/trade";
                } else if (menu.type === "todayKaifu") {
                  return "/server";
                } else if (menu.type === "giftCenter") {
                  return "/gift";
                } else if (menu.type === "userGame") {
                  return "/user/game";
                }
              })()}
              iframe={menu.type === "h5" ? { title: menu.name } : false}
              user={menu.is_verification_login == 1 ? true : false}
              onClick={() => {
                badge[menu.id] = true;
                setBadge(badge);
                if (menu.id === "233") {
                  spm(`user.game`);
                }
              }}
            >
              <div className="icon">
                <Image src={menu.icon} alt="" />
              </div>
              <div className="name">{menu.name}</div>
              <div className="scrollbar">
                <div className="thumb" ref={scrollbarRef} />
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      {/* 胶囊 */}
      {popularGame.length && showBanner ? (
        <div className="index-activity_top">
          <Swiper
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
          >
            {popularGame.map((item) => (
              <SwiperSlide key={item.id}>
                <NavLink
                  user={item.if_check == 1 ? true : false}
                  iframe={
                    item.type == "outer_h5" ? { title: item.title } : null
                  }
                  to={urlMatching(item)}
                  href={item.type == "outer_contact" ? item.param : ""}
                >
                  <img src={item.img} alt="" />
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}

      {/* 游戏及专题展示 */}
      {gameDatas.map((item) => {
        // 1 游戏列表 2 专题
        return item.type == 1 ? (
          item.ob_type == 1 ? (
            <div
              className="index-data recommendGames"
              style={{ padding: "16px 12px" }}
              key={item.id}
            >
              <div className="data-head recommendGames">
                <div className="text">{item.title}</div>
              </div>
              <div className="data-body recommendGames">
                <div className="items">
                  {item.details.map((game) => (
                    <Link
                      to={"/game?id=" + game.game_id}
                      key={game.game_id}
                      className="item"
                    >
                      <div className="icon">
                        <Image src={game.icon} />
                      </div>
                      {game.game_type == 2 && game.discount < 1 ? (
                        <div className="index-all-discount">
                          <span
                            style={{ fontSize: "0.24rem", fontWeight: "bold" }}
                          >
                            {parseFloat((game.discount * 10).toFixed(2))}
                          </span>
                          <span style={{ fontSize: "0.2rem" }}>折</span>
                        </div>
                      ) : null}
                      <div className="name">{game.game_name_main}</div>
                      <div className="info">{game.version}</div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ) : item.ob_type == 2 ? (
            <div className="index-data columnGames" key={item.id}>
              <div className="data-head columnGames-head">
                <span className="text">{item.title}</span>
              </div>
              <div className="data-body columnGames">
                {item.details.map((game, index) => (
                  <Link
                    style={{
                      paddingBottom:
                        game.is_show_banner_url == 1 &&
                        index === item.details.length - 1
                          ? 0
                          : "16px",
                    }}
                    to={"/game?id=" + game.game_id}
                    key={game.game_id}
                  >
                    <div className="item">
                      <div className="icon">
                        <Image src={game.icon} />
                      </div>
                      {game.game_type == 2 && game.discount < 1 ? (
                        <div className="index-column-discount">
                          <span
                            style={{ fontSize: "0.28rem", fontWeight: "bold" }}
                          >
                            {parseFloat((game.discount * 10).toFixed(2))}
                          </span>
                          <span style={{ fontSize: "0.2rem" }}>折</span>
                        </div>
                      ) : null}

                      <div className="game-info">
                        <div className="name">
                          <div>{game.game_name_main}</div>
                          {game.game_name_branch ? (
                            <div className="versions">
                              {game.game_name_branch}
                            </div>
                          ) : null}
                        </div>
                        <div className="info">
                          {game.version}
                          {game.played_count && game.played_count > 0 ? (
                            <div className="info" style={{ marginTop: 0 }}>
                              <div className="circle"></div>
                              {game.played_count}w人在玩
                            </div>
                          ) : null}
                        </div>
                        <div className="info">
                          {game.benefits
                            ? game.benefits.split("+").map((item, index) => (
                                <div className="desc" key={index}>
                                  {item}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                    {game.is_show_banner_url == 1 ? (
                      <div className="columnGames-bigImg">
                        <Image src={game.banner_url} />
                      </div>
                    ) : null}
                  </Link>
                ))}
              </div>
            </div>
          ) : item.ob_type == 3 ? (
            <div key={item.id}>
              {item.details.map((game) => (
                <div key={game.game_id} className="index-new-game-promotion">
                  <NavLink
                    user={item.if_check == 1 ? true : false}
                    to={
                      item.jump_type === "game_newinfo"
                        ? `/game?id=${game.game_id}`
                        : item.param
                    }
                    iframe={
                      item.jump_type == "outer_h5"
                        ? { title: item.title }
                        : null
                    }
                  >
                    <div className="index-new-game-promotion-top">
                      <img
                        src={item.url_img}
                        alt=""
                        className="index-new-game-promotion-img"
                      />

                      <div
                        className="new-game-welfare"
                        style={{
                          backgroundImage: `linear-gradient(to top, ${item.color} 80%, transparent)`,
                        }}
                      >
                        {item.introduction}
                      </div>
                    </div>

                    <div
                      className="index-new-game-promotion-font"
                      style={{
                        backgroundColor: item.color,
                      }}
                    >
                      <img
                        className="cut-off-rule-img"
                        src="/resources/index/cut-off-rule.png"
                        alt=""
                      />
                      <div className="item">
                        <div className="icon">
                          <Image src={game.icon} />
                          {game.game_type == 2 && game.discount < 1 ? (
                            <div className="index-all-discount">
                              <span
                                style={{
                                  fontSize: "0.24rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {parseFloat((game.discount * 10).toFixed(2))}
                              </span>
                              <span style={{ fontSize: "0.2rem" }}>折</span>
                            </div>
                          ) : null}
                        </div>
                        <div style={{ overflow: "hidden" }}>
                          <div className="name">
                            <div className="game-name">
                              {game.game_name_main}
                            </div>
                            {game.game_name_branch ? (
                              <div className="versions">
                                {game.game_name_branch}
                              </div>
                            ) : null}
                          </div>
                          <div className="info">
                            {game.version}
                            {game.played_count && game.played_count > 0 ? (
                              <div className="info" style={{ marginTop: 0 }}>
                                <div className="circle"></div>
                                {game.played_count}w人在玩
                              </div>
                            ) : null}
                          </div>
                          <div className="info">
                            {game.benefits
                              ? game.benefits.split("+").map((item, index) => (
                                  <div className="desc" key={index}>
                                    {item}
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          ) : null
        ) : item.type == 2 ? (
          <NavLink
            key={item.id}
            className="index-data projectGames"
            user={item.if_check == 1 ? true : false}
            to={
              item.jump_type === "game_newinfo"
                ? `/game/indexProject?id=${item.param}`
                : item.param
            }
            iframe={item.jump_type == "outer_h5" ? { title: item.title } : null}
          >
            <div className="data-head projectGames-head">
              <div className="text">{item.title}</div>
              <div className="more">
                更多
                <RightOutlined
                  style={{
                    fontSize: "8px",
                    color: "#999",
                    marginLeft: "2px",
                  }}
                />
              </div>
            </div>
            <div className="data-body projectGames">
              <div>
                <Image src={item.url_img} alt="" />
              </div>
            </div>
          </NavLink>
        ) : null;
      })}

      {/* 精选分类 */}
      {selectiveSlassification.length > 0 ? (
        <div
          className="index-data recommendGames"
          style={{ padding: "15px 16px 24px" }}
        >
          <div className="data-head recommendGames">
            <div className="text">精选分类</div>
          </div>
          <div className="selective-classification">
            {selectiveSlassification.map((classify, index) => (
              <div className="item" key={index}>
                <div
                  className="container"
                  onClick={() => {
                    general.tag = classify.id;
                    redux.dispatch({
                      type: "type",
                      data: {
                        screenRouter: {
                          screen: "/game",
                          type: 0,
                          tag: classify.id,
                        },
                      },
                    });
                  }}
                >
                  {classify.tagname}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div className="index-tips">
        <div className="text">更多游戏请到游戏大厅中查看</div>
      </div>

      {/* 这个样式目前直接设置的  display: none  */}
      <div className="index-float">
        <div className="item 648" onClick={() => setAlert(true)}>
          <Image
            className={app.brand == "btwan" ? "btwan" : ""}
            src={`/resources/index/${
              app.brand == "btwan"
                ? "alert-btwan"
                : user.back2203
                ? "back2203"
                : "float-648"
            }.png`}
            alt=""
          />
        </div>
        {activity ? (
          <div className="item first" onClick={() => setActivityShow(true)}>
            <Image src={activity.thumb} alt="" />
          </div>
        ) : !user.isLogin || !user.is_finish_newtask ? (
          <div className="item first" onClick={() => setFirstActivity(true)}>
            <Image src="/resources/index/float-first.png" alt="" />
          </div>
        ) : null}
      </div>

      {/* data目前返回都为null  此模块也都一直不显示 */}
      {activity ? (
        <>
          {!activityTime || activityShow ? (
            <div className="activity-first">
              <div className="container">
                <NavLink
                  to={
                    activity.type == "outer_h5"
                      ? activity.url
                      : `/game?id=${activity.url}`
                  }
                  iframe={{ title: activity.name }}
                  onClick={() => {
                    setActivityShow(false);
                    setActivityTime(true);
                  }}
                >
                  <div className="img">
                    <Image src={activity.img} alt="" />
                  </div>
                </NavLink>
                <div
                  className="close"
                  onClick={() => {
                    setActivityShow(false);
                    setActivityTime(true);
                  }}
                >
                  X
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : firstActivity ? (
        <div className="activity-first">
          <div className="container">
            <NavLink
              to="/screen-welfare"
              onClick={() => setFirstActivity(false)}
            >
              <div className="img">
                <Image src="/resources/index/activity-first.png" alt="" />
              </div>
              <div className="btn">立即领取</div>
            </NavLink>
            <div className="close" onClick={() => setFirstActivity(false)}>
              X
            </div>
          </div>
        </div>
      ) : null}

      {navigator.userAgent == "app" &&
      !appUdid &&
      !general.isLogin() &&
      key &&
      false ? (
        <div className="app-udid">
          <div className="container">
            <div
              className="img"
              onClick={() => {
                history.push("login");
                /*window.location.href = URI('app://browser')
            .addQuery('url', URI('https://app.9917.cn/udid/').addQuery('url', URI(window.location.href)
                .hash(
                  URI(location.pathname + location.search + location.hash)
                    .addQuery('agent', agent)
                    .addQuery('key', key)
                    .addQuery('auto', 1)
                    .toString())
                .toString()
              )
                .toString()
            ).toString();*/
                /*window.location.href = URI('app://browser')
            .addQuery('url', `itms-services://?action=download-manifest&url=${encodeURIComponent(`https://iosd.9917.cn/152/${agent}/manifest`)}`)
            .toString();*/
                setAppUdid(true);
              }}
            >
              <Image src="/resources/index/app.png" alt="" />
            </div>
            <div
              className="btn"
              onClick={() => {
                history.push("login");
                /*window.location.href = URI('app://browser')
            .addQuery('url', `itms-services://?action=download-manifest&url=${encodeURIComponent(`https://iosd.9917.cn/152/${agent}/manifest`)}`)
            .toString();*/
                setAppUdid(true);
              }}
            >
              立即领取
            </div>
            <div className="close" onClick={() => setAppUdid(true)}>
              X
            </div>
          </div>
        </div>
      ) : null}

      {(first &&
        window.navigator.standalone &&
        navigator.userAgent != "app" &&
        !user.isLogin &&
        app.brand !== undefined) ||
      alert ||
      (user?.back2203 && user.back2203.receive != 1) ? (
        <div className="app-alert">
          <div className="container">
            <NavLink
              className="img"
              to={`${sysUrl}/home/NewPerson2`}
              iframe={{ title: "新人见面礼" }}
            >
              <Image
                src={`/resources/index/${
                  user.back2203
                    ? "back2203"
                    : `alert${app.brand ? `-${app.brand}` : ""}`
                }.png`}
                alt=""
                onClick={() => {
                  setFirst(false);
                  setAlert(false);
                }}
              />
            </NavLink>
            <div
              className="close"
              onClick={() => {
                setFirst(false);
                setAlert(false);
              }}
            >
              X
            </div>
          </div>
        </div>
      ) : null}

      {/*{!guide ? <Act/> : null}*/}
      <Act advertis={advertis} isShow={props.isShow} active={actActive} />
    </div>
  );
};

export default (props) => {
  const [state, setState] = useState(123);
  const scroll = useScroll();
  useEffect(() => {
    if (props.active && scroll && scroll?.top < -80) {
      setState(dayjs().unix());
    }
  }, [scroll, props.active]);

  return <View {...props} key={state} />;
};
