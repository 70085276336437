import { useContext, useEffect, useState } from "react";
import ActiveContext from "./ActiveContext";
import general from "../general";
import { useMount, useUnmount, useUpdateEffect } from "ahooks";

// 使用 Set 来存储数据，避免重复和方便查找
const dataSet = new Set();

const useActiveState = (name) => {
  const [state, setState] = useState(general[name]);

  const activeSetState = (newState) => {
    // 如果 newState 是 null 或 undefined，赋值""
    if (newState === "null" || newState === null || newState === undefined) {
      general[name] = ""; // newState;
    } else if (typeof newState === "string") {
      // 如果是字符串，直接赋值
      general[name] = newState;
    } else {
      // if (typeof newState === "object")
      // 如果是对象，合并状态
      general[name] = { ...general[name], ...newState };
    }

    // 调用对应的 setter 方法
    const setter = `set${name[0].toUpperCase()}${name.slice(1)}`;
    if (general[setter]) {
      general[setter]();
    }

    // 更新本地状态
    setState(newState);
  };

  useMount(() => {
    const data = { name, state, setState, activeSetState };
    dataSet.add(data);
  });

  useUnmount(() => {
    for (const data of dataSet) {
      if (data.setState === setState) {
        dataSet.delete(data);
        break;
      }
    }
  });

  const active = useContext(ActiveContext);

  useEffect(() => {
    if (active) {
      setState(general[name]);
    }

    for (const data of dataSet) {
      if (data.setState === setState) {
        data.active = active;
        break;
      }
    }
  }, [active]);

  useUpdateEffect(() => {
    for (const data of dataSet) {
      if (data.active && data.name === name && data.setState !== setState) {
        data.setState(state);
      }
    }
  }, [state]);

  return [state, activeSetState];
};

export default useActiveState;
