import React, { useEffect, useState, useRef } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useReactive } from "ahooks";
import URI from "urijs";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Getter from "./Getter";
import Guide from "./Guide";
import Reserve from "./Reserve";
import Tips from "./Tips";
import Image from "../../../lib/Image";
import CryptoJS from "crypto-js";
import general from "../../../general";
import { behaviorTrigger } from "../../../utils/trigger";

const { aplus_queue } = window;

const Main = ({ game, close }) => {
  const [udid] = useActiveState("udid");
  const data = useReactive({
    udidGetter: undefined,
    interval: undefined,
    state: undefined,
    reserve: undefined,
    tips: undefined,
    url: undefined,
    frequency: 1,
  });

  let [progressNum, setProgressNum] = useState(0);

  useEffect(() => {
    if (udid) {
      data.interval = 3000;
      downloadIos();

      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "download",
          "download",
          {
            type: "vip",
            state: "start",
            gameId: game?.game_id,
            agent: localStorage.getItem("agent"),
            username: localStorage.getItem("username"),
          },
        ],
      });
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
        setTimerId(null);
      }

      data.interval = undefined;
      close();
    };
  }, []);

  const encodeAndHash = (value) => {
    // Base64 编码
    const base64Encoded = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(value)
    );

    // MD5 加密
    const md5Hash = CryptoJS.MD5(base64Encoded).toString(CryptoJS.enc.Hex);

    return md5Hash;
  };

  let iosObjData = {};
  let [timerId, setTimerId] = useState(null);

  const downloadIos = () => {
    Dialog.close();
    Dialog.loading();

    if (!game?.maiyou_gameid) {
      Dialog.error("缺少必要参数gameid，请重试");
      return;
    }

    if (!udid) {
      Dialog.error("缺少必要参数udid，请重试");
      return;
    }

    let tgmag = localStorage.getItem("agent") || "x7z1";

    let tgmudid = localStorage.getItem("tgm_uuid")
      ? tgmag + "~" + localStorage.getItem("tgm_uuid")
      : tgmag;

    if (localStorage.getItem("tgm_uuid")) {
      // 密钥和明文
      var key = "1234567890123456"; // 密钥长度需为16字节（128位）
      var plaintext = tgmudid;

      // 确保密钥长度为16字节
      key = CryptoJS.enc.Utf8.parse(key);

      // 加密数据
      var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      // 转换为十六进制字符串（bin2hex 类似操作）
      var encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

      tgmudid = encryptedHex;
    }

    let appId = "991701";
    let time = new Date().getTime().toString();

    iosObjData = {
      appid: appId,
      agent: tgmudid,
      device_type: "ios",
      game_id: game?.maiyou_gameid,
      remark: CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(
          `{"bd_vid":"${localStorage.getItem(
            "bd_vid"
          )}","token":"${localStorage.getItem(
            "token"
          )}","username":"${localStorage.getItem("username")}"}`
        )
      ),
      signature_type: "v1",
      subpackage_type: "9917",
      timestamp: time,
      version: "",
      username: localStorage.getItem("username") || "",
      userid: localStorage.getItem("user_id") || 0,
      udid,
      depart: tgmudid.length > 8 ? "info" : "",
    };

    // 获取对象的键并排序
    const sortedKeys = Object.keys(iosObjData).sort();
    // 使用排序后的键生成一个新的对象
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = iosObjData[key];
    });

    let sign = `${appId}${JSON.stringify(
      sortedObj
    )}4C5CBC70C5FF6742D133D93D730D4C96`;

    general.axios
      .post("/handle/subpackage", {
        ...iosObjData,
        sign: encodeAndHash(sign),
      })
      .then((res) => {
        console.log("下载---", res);

        let resInfo = res.data;
        if (resInfo.code !== 200) {
          Dialog.close();
          data.interval = undefined;
          close();
          return Dialog.error(resInfo.msg);
        }

        Dialog.close();

        if (resInfo?.data?.subpackageDownload == "签名中") {
          // 如果定时器已经存在，则不重复设置
          if (timerId) {
            console.log("定时器已存在");
            return;
          }

          // 设置定时器
          const id = setInterval(() => {
            iosReqSucc();

            // 后端分包进度
            let n = 6;
            let size = game?.game_size?.ios_size?.split(" ")[0];

            if (size < 50) {
              n = Math.ceil(Math.random() * 13) + 13;
            } else if (size < 150) {
              n = Math.ceil(Math.random() * 10) + 10;
            } else if (size < 500) {
              n = Math.ceil(Math.random() * 8) + 8;
            } else if (size < 1000) {
              n = Math.ceil(Math.random() * 4) + 4;
            } else {
              n = Math.ceil(Math.random() * 2) + 2;
            }

            //前端虚拟当前要展示的进度
            let progress_bar_data = progressNum + n;

            // 虚拟进度条小于99才展示，大于就不再继续赋值增加后的数值了
            if (progress_bar_data < 99) {
              progressNum = progress_bar_data;
              setProgressNum(progress_bar_data);
            }
          }, 1000);

          // 存储定时器 ID
          timerId = id;
          setTimerId(id);
        }
      })
      .catch((error) => {
        data.interval = undefined;
        close();
        Dialog.close();
        Dialog.error(error);
      });
  };

  const iosReqSucc = () => {
    let appId = "991701";

    // 获取对象的键并排序
    const sortedKeys = Object.keys(iosObjData).sort();
    // 使用排序后的键生成一个新的对象
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = iosObjData[key];
    });

    let sign = `${appId}${JSON.stringify(
      sortedObj
    )}4C5CBC70C5FF6742D133D93D730D4C96`;

    general.axios
      .post("/show/subpackage", {
        ...iosObjData,
        sign: encodeAndHash(sign),
      })
      .then((res) => {
        console.log("获取分包信息---", res);

        let resInfo = res.data;
        if (resInfo.code !== 200) {
          if (timerId) {
            clearInterval(timerId);
            timerId = null;
            setTimerId(null);
          }

          data.interval = undefined;
          close();
          Dialog.close();
          return Dialog.error(resInfo.msg);
        }

        if (resInfo?.data?.downPath) {
          if (localStorage.getItem("tgm_uuid")) {
            behaviorTrigger("downloadpkg");
          }

          if (timerId) {
            clearInterval(timerId);
            timerId = null;
            setTimerId(null);
          }

          setTimeout(() => {
            progressNum = 100;
            setProgressNum(100);

            setTimeout(() => {
              data.interval = undefined;
              close();
              Dialog.close();

              if (navigator.userAgent == "app") {
                window.location.href = URI("app://browser")
                  .addQuery("url", resInfo.data.downPath)
                  .toString();
              } else {
                window.location.href = resInfo.data.downPath;
              }
            }, 1000);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("media-point " + error);

        if (timerId) {
          clearInterval(timerId);
          timerId = null;
          setTimerId(null);
        }

        data.interval = undefined;
        close();
        Dialog.close();
        Dialog.error(error);
      });
  };

  const cancelDown = () => {
    if (timerId) {
      clearInterval(timerId);
      timerId = null;
      setTimerId(null);
    }
    data.interval = undefined;
    close();
  };

  return (
    <>
      {udid ? (
        <>
          {data.interval ? (
            <TransitionGroup>
              <CSSTransition timeout={250}>
                <div className="game-new-super">
                  <div className="new-super-main">
                    <div
                      className="cancel-btn"
                      onClick={() => {
                        Dialog.affirm(
                          "温馨提示",
                          <div style={{ textAlign: "center" }}>
                            您确定取消游戏下载？
                          </div>,
                          cancelDown,
                          () => {
                            Dialog.close();
                          },
                          {
                            close: "确定",
                            affirm: "继续等待",
                          }
                        );
                      }}
                    >
                      取消
                    </div>
                    <div className="super-game-msg">
                      <Image src={game?.game_image?.thumb} />
                      <div className="name">
                        {game?.game_name_main || game?.game_name}
                        {game?.game_name_branch &&
                          " - " + game?.game_name_branch}
                      </div>
                    </div>
                    <div className="super-game-progress">
                      <div
                        className="progress"
                        id="progress"
                        style={{ width: progressNum + "%" }}
                      ></div>
                      <div className="font">
                        分包签名中，勿退出{progressNum}%
                      </div>
                    </div>
                    <div className="tips">
                      <ExclamationCircleOutlined
                        style={{ marginRight: "2px", fontSize: "0.28rem" }}
                      />
                      请不要离开此页面并保持屏幕常亮
                    </div>
                    <div className="udid-msg">
                      <span>UDID：{udid}</span>

                      <CopyToClipboard
                        text={udid}
                        onCopy={() => Dialog.info("已复制")}
                      >
                        <p>复制</p>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          ) : null}
        </>
      ) : (
        <>
          <Getter close={close} />
        </>
      )}
      {data.reserve ? (
        <>
          <Reserve game={game} close={close} />
        </>
      ) : null}
      {data.tips ? (
        <>
          <Tips url={data.url} close={close} />
        </>
      ) : null}
    </>
  );
};

export default (props) => {
  const [udid] = useActiveState("udid");

  return (
    <>
      {udid && (
        <Guide>
          <Main {...props} />
        </Guide>
      )}
    </>
  );
};
