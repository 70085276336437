import { useMount, useReactive } from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import { useEffect } from "react";
import { spm } from "../../../general";

export default ({
  game,
  type,
  close,
  callback: _callback,
  superGo: _superGo,
  cloud: _cloud,
}) => {
  const [config] = useActiveState("config");
  const data = useReactive({
    game: `《${game?.game_name_main || game?.game_name}》${
      game?.game_name_branch || ""
    }`,
    size: game?.game_size?.ios_size,
    superable: config.udid_usable,
    interflow: game?.cloud_is_double,
    clouded: game?.cloudPlayed,
    plan: undefined,
  });

  useEffect(() => {
    console.warn(game);
    console.warn(data);
    console.warn({ type, plan: data.plan });
  });

  const callback = () => {
    _callback();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.next`);
  };
  const cloudGo = () => {
    _cloud();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.cloud`);
  };
  const superGo = () => {
    _superGo();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.download`);
  };

  useMount(() => {
    data.spm = type === "super" ? "download" : type;

    // else if (type === "super" && !data.interflow && data.clouded) {
    //   data.plan = "backSuper";
    // }

    if (type === "cloud" && !data.interflow && !data.superable) {
      data.plan = "cloud";
    } else if (
      type === "cloud" &&
      !data.interflow &&
      data.superable &&
      !data.clouded
    ) {
      data.plan = "cloudSuperable";
    } else if (type === "auto") {
      data.plan = "auto";
    }
    if (!data.plan) {
      callback();
    }
  });

  return (
    <>
      {!!data.plan && (
        <>
          <div className="game-intercept">
            <div className="gi-container">
              <div className="gi-head">
                <span style={{ flex: 1, textAlign: "center" }}>温馨提示</span>
                <img
                  src="/resources/game/close.png"
                  style={{ width: "15px" }}
                  alt=""
                  onClick={() => {
                    close();
                  }}
                />
              </div>
              <div className="gi-body">
                <div className="text">
                  {data.plan === "cloud" ||
                  data.plan === "backSuper" ||
                  data.plan === "cloudSuperable" ? (
                    <>{data.game}游戏 双端不互通，云玩所产生的数据属于安卓端</>
                  ) : null}

                  {data.plan === "auto" ? (
                    <>udid获取成功，请现返回APP继续畅玩。</>
                  ) : null}
                </div>
              </div>
              <div className="gi-foot">
                <div className="btn" onClick={callback}>
                  我已知晓
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
