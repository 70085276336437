import { useEffect, useRef, useState } from "react";
import general, { imgPlaceholder } from "../../general";
import {
  CloseOutlined,
  ShareAltOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  useLocalStorageState,
  useLockFn,
  useMount,
  useReactive,
  useScroll,
  useThrottleEffect,
  useThrottleFn,
  useUpdateEffect,
} from "ahooks";
import { useHistory, useLocation } from "react-router-dom";
import Index from "./detail/Index";
import Server from "./detail/Server";
import Activity from "./article/Groups";
import Comment from "./detail/Comment";
import ToolCabinet from "./detail/ToolCabinet";
import { Swiper, SwiperSlide } from "swiper/react";
import { CSSTransition } from "react-transition-group";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { NavLink } from "../../lib/Navigation";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import dayjs from "dayjs";
import useUrlState from "@ahooksjs/use-url-state";
import MobileDetect from "mobile-detect";
import URI from "urijs";
import qs from "qs";
import Super from "./ios/Super";
import Intercept from "./ios/Intercept";
import Require from "./ios/Require";
import Auto from "./ios/Auto";
import Active from "../../lib/Active";
import _ from "lodash";
import $ from "jquery";
import uni from "@dcloudio/uni-webview-js";
import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";

import { getUuidCallback, behaviorTrigger } from "../../utils/trigger";

// 游戏详情页
const mobileDetect = new MobileDetect(navigator.userAgent);

const router = [
  {
    id: "index",
    component: Index,
    name: "详情",
  },
  {
    id: "comment",
    component: Comment,
    name: "评论",
    count: "",
  },
  {
    id: "activity",
    component: Activity,
    name: "活动",
  },
  {
    id: "tool",
    component: ToolCabinet,
    name: "工具箱",
  },
];

const Tips = (props) => {
  const [state, setState] = useLocalStorageState(
    `game-tips-${props.game.game_id}`
  );

  return (
    <>
      {!state ? (
        <>
          <div className="game-tips" onClick={() => setState(true)}>
            <div className="text">若游戏无法正常安装，请及时联系客服处理。</div>
            <CloseOutlined className="icon" />
          </div>
        </>
      ) : null}
    </>
  );
};

const spm = (spm) => general.axios.post("/spm", { spm });

export default (props) => {
  const history = useHistory();
  let [udid, setUdid] = useActiveState("udid");

  // 示例公钥 (通常从服务器获取)
  const publicKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAu5phb2FQQiuZSlSxI09q4Mc3LIpuUTZP5+EBe480c9/zDkVgPmuIQcBMPbi5NC5yxpYOCUZkLjC8VDzMqDgS89c6hC3qY0btRBxON7gO9pk4ijUEa2xlvnefvYgXMpks13FT5To852iguQbW2k53DHD6Efsvbvh/Nl1kRXhYGPw3XWAQeDNS9xFESpx+9r4KWcm8D/R2fnv5w1P/UvcNrYVDMTGkEmOGnVl96WZBPcHuZRrRcXcbzTE3TMzabQAdqR+FDPRPWpK5F/WUuWWbKL+MS3dvREdOxYPgIfvyPm4ztLp0I+v06/fZvUOembo4Bokj0AusbYKqUkp3s6El11xmHyaRJULh9PYJ9xfte1oihnRpU+HenVUfHBQBy8XBzHkhUsVfKV+WGaARYRQ2efSsTojji8WBXpt63kkkltcgSZJvQ9gPcSTAz3xRkX6tRTEA61AsbT1ua+uJlFWa97P3xHd+SGcOYsOjSbnpB/WGxBMs7dX2FEuevaEEgyJr+d336jhzK/6Mc6eaIKlPPZyZfXUH/KK3YIllDSx2WbAHibTZfetp/76KL+QBLLv2QFGwjWZKxCZ/M2zRvRGL1wLLuw5DAr8LiodEt1YABpVO28YNwf87zpt4XZ7bv4ubNP8NYONSLAB7YqPUnG58UQ8I3Yvw2k3Nmos/Cs/vFasCAwEAAQ==-----END PUBLIC KEY-----`;
  const token = () => {
    console.log("mountttttttttttttttt");
    let uri = new URI(window.location.hash.substring(1));
    let query = uri.query(true);
    // console.log("query---------------", query);

    if (query.appDownload && query.bd_vid) {
      localStorage.setItem("bd_vid", query.bd_vid);
    }

    if (query.appDownload && query.uuid) {
      localStorage.setItem("tgm_uuid", query.uuid);
    }

    if (
      query.udid &&
      (query.user_id || query.userid) &&
      query.username &&
      query.token
    ) {
      setUdid(query.udid);

      // 安装描述文件后 跳转回盒子时 此时用户绝对已签名
      // 获取
      console.log("安装描述文件成功回来啦~~~~~~~~", udid);

      localStorage.setItem("user_id", query.user_id);
      localStorage.setItem("username", query.username);
      localStorage.setItem("token", query.token);
      localStorage.setItem("key", query.key);
      localStorage.setItem("bd_vid", query.bd_vid);
      localStorage.setItem("tgm_uuid", query.uuid);

      if (!query.appDownload) {
        query.user_id = undefined;
        query.userid = undefined;
        query.username = undefined;
        query.token = undefined;

        uri.query(query);
        console.warn(uri.toString());
        history.replace(uri.toString());
      }
    }
  };
  useMount(token);
  useMount(history.listen(token));
  const [params] = useUrlState();
  const [config, setConfig] = useActiveState("config");
  const [key] = useActiveState("key");

  let [agent] = useActiveState("agent");
  useEffect(() => {
    props.setTitle("");
    props.setOperate(
      <CopyToClipboard
        text={window.location.href}
        onCopy={() => Dialog.info("已复制链接")}
      >
        <ShareAltOutlined />
      </CopyToClipboard>
    );
  }, []);

  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [game, setGame] = useState(null);
  // 免签名下载按钮是否展示
  const [downloadSigning, setDownloadSigning] = useState(false);
  // 至尊版(需要超级签)下载按钮是否展示
  const [iosBtnShow, setIosBtnShow] = useState(true);
  // 下载走新代码还是旧代码
  const [isShowOld, setIsShowOld] = useState(0);

  let gameData = null;

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    let data = {};
    if (props.query.id) {
      data.game_id = props.query.id;
    } else if (props.query.mid) {
      data.maiyou_gameid = props.query.mid;
    } else {
      Dialog.error("缺少参数", 1, () => history.goBack);
      return;
    }
    general.axios
      .post("/base/game/getGameDetail", data)
      .then(async (res) => {
        console.log("详情游戏------", res);
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        let game = { ...data, ...data.game_info };
        if (game.game_feature_list) {
          let result = /<table[^>]*?>([\s\S]*)<\/table>/g.exec(
            game.game_feature_list[2].content
          );
          if (result && result[0]) {
            game.vipprice = result[0];
          }
        }
        if (game.status == 0) {
          game.cloud_support_type = "";
          game.cloudDisabled = 1;
        }

        if (game.game_species_type == 3) {
          if (!localStorage.getItem("tgm_uuid")) {
            await getUuidCallback();
          }

          let tgmag = localStorage.getItem("agent") || "x7z1";
          let tgmudid = localStorage.getItem("tgm_uuid")
            ? tgmag + "~" + localStorage.getItem("tgm_uuid")
            : tgmag;

          console.log("有uuid------------", localStorage.getItem("tgm_uuid"));

          if (localStorage.getItem("tgm_uuid")) {
            // 密钥和明文
            var key = "1234567890123456"; // 密钥长度需为16字节（128位）
            var plaintext = tgmudid;

            // 确保密钥长度为16字节
            key = CryptoJS.enc.Utf8.parse(key);

            // 加密数据
            var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7,
            });

            // 转换为十六进制字符串（bin2hex 类似操作）
            var encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
            console.log("Encrypted (Hex):", encryptedHex);

            tgmudid = encryptedHex;

            let gameUrlH5 = game.game_url.android_url.split("&");
            gameUrlH5[1] = "agent=" + tgmudid;
            gameUrlH5 = gameUrlH5.join("&");
            console.log("tong----", gameUrlH5);

            game.game_url.android_url = gameUrlH5;
          }
        }

        gameData = game;
        setGame(game);
        general.game = game;
        console.log("游戏详情", game);

        topHeight = navRef.current.clientHeight || 100;
        setTopHeight(navRef.current.clientHeight || 100);

        if (
          game?.cloud_support_type === "sq" &&
          game?.cloudDisabled === 0 &&
          props.query.cloudType === "start"
        ) {
          intercept("cloud", playCloudGame);
          spm(`game.${game?.maiyou_gameid}.cloud`);
        }

        return general.axios.get("/base/game/isshowselfsign", {
          params: {
            game_id: game?.maiyou_gameid,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      })
      .then((res) => {
        console.log("是否展示免签名包体", res, game);
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        console.log("是否要自动下载~~~~~~~~~", props.query, game);
        if (game?.game_species_type != 3 && props.query.download) {
          if (data.show) {
            signingDownload();
          }

          if (data.iosshow) {
            downloadGame();
          }
        }

        setDownloadSigning(data.show);
        setIosBtnShow(data.iosshow);
        setIsShowOld(data.supershow);
      })
      .catch((err) => {
        // Dialog.error('getGameDetail ' + err.message);
      });

    if (localStorage.getItem("tgm_uuid")) {
      behaviorTrigger("browsing");
    }

    getUdidVal();
  };

  const playCloudGame = (spm = "normal", assign = null, desktop = false) => {
    general.referrer = window.location.href;
    history.push(
      `/game/cloud?${qs.stringify({
        id: gameData?.game_id || game?.game_id,
        agent: localStorage.getItem("agent") || "x7z1",
        user: localStorage.getItem("user_id"),
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
        spm,
        assign,
        auto: desktop ? "desktop" : null,
      })}`
    );
  };

  const [needLogin, setNeedLogin] = useState(false);
  // useEffect(async () => {
  //   let is_bind = await isCallFunc();
  //   setNeedLogin(is_bind);
  // }, []);

  useEffect(() => {
    load();
  }, [localStorage.getItem("token")]);

  const getUdidVal = async () => {
    // && !udid
    if (key) {
      let userAgent = window.navigator.userAgent;
      await general.axios
        .post("/base/newindex/getKeyUdid", { key, udid, ua: userAgent })
        // .finally(() => {
        //   Dialog.close();
        // })
        .then(({ data: resData }) => {
          console.log("游戏详情---获取udid信息-----", resData);
          let { status, data } = resData;
          //  && data.udid
          if (status.succeed) {
            setUdid(data.udid);
          }
        })
        .catch((error) => Dialog.error("setKeyUdid " + error.message));
    }
  };

  const [colour, setColour] = useState();
  const scroll = useScroll();

  const { run: _setColour } = useThrottleFn(
    (value) => {
      setColour(value);
    },
    { wait: 10 }
  );
  useEffect(() => {
    if (scroll && scroll.top) {
      let opacity = Math.abs(scroll.top) / 40;
      if (opacity > 1) {
        opacity = 1;
      }
      _setColour("rgba(255,255,255, " + opacity + ")");
    } else {
      _setColour("#202737");
    }
  }, [scroll, props.active]);

  useEffect(() => {
    history.listen((location) => {
      // console.log("请求路由路径变化了", location);
      Dialog.close();
    });
  });

  useUpdateEffect(() => {
    if (props.active && init) {
      if (scroll.top) {
        let opacity = Math.abs(scroll.top) / 40;
        if (opacity > 1) {
          opacity = 1;
        }
        setColour("rgba(255,255,255, " + opacity + ")");
        props.setTitle(
          <span style={{ opacity: opacity }}>
            {game?.game_name_main || game?.game_name}
          </span>
        );
      } else {
        setColour("#202737");
        props.setTitle(
          <span style={{ opacity: 0 }}>
            {game?.game_name_main || game?.game_name}
          </span>
        );
      }
    }
    if (props.active) {
      if (scroll?.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  const [routerIndex, setRouterIndex] = useState(
    parseInt(props.query.page) || 0
  );
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [props.active, swiper]);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(routerIndex);
    }
  }, [routerIndex, swiper]);

  const ref = useRef(null);

  const favorite = () => {
    setGame({ ...game, is_collected: !game.is_collected });
    general.axios
      .post(game.is_collected ? "/cancelCollectGame" : "/collectGame", {
        id: game?.maiyou_gameid,
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          Dialog.success("操作成功");
        }
      })
      .catch((err) => {
        Dialog.error("collectGame " + err.message);
      });
  };

  const encodeAndHash = (value) => {
    // Base64 编码
    const base64Encoded = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(value)
    );

    // MD5 加密
    const md5Hash = CryptoJS.MD5(base64Encoded).toString(CryptoJS.enc.Hex);

    return md5Hash;
  };

  const downloadAnd = useLockFn(async () => {
    if (
      mobileDetect.is("iPhone") ||
      mobileDetect.is("iPad") ||
      navigator.userAgent == "app" ||
      navigator.userAgent == "uniapp"
    ) {
      if (downloadSigning) {
        signingDownload();
      }

      if (iosBtnShow) {
        downloadGame();
      }

      // Dialog.loading();

      // await general.axios
      //   .post(game.game_url.ios_url.replace("http", "https"))
      //   .finally(() => {
      //     Dialog.close();
      //   })
      //   .then((res) => {
      //     let { status, data } = res.data;
      //     if (!status.succeed) {
      //       Dialog.error(status.error_desc);
      //     } else {
      //       window.location.href = data.url;
      //     }
      //   })
      //   .catch((err) => {
      //     Dialog.error("game_url " + err.message);
      //   });
    } else {
      // window.location.href = game.game_url.android_url;

      Dialog.close();
      Dialog.loading();

      if (!game?.maiyou_gameid) {
        Dialog.error("缺少必要参数gameid，请重试");
        return;
      }

      let tgmag = localStorage.getItem("agent") || "x7z1";

      let tgmudid = localStorage.getItem("tgm_uuid")
        ? tgmag + "~" + localStorage.getItem("tgm_uuid")
        : tgmag;

      if (localStorage.getItem("tgm_uuid")) {
        // 密钥和明文
        var key = "1234567890123456"; // 密钥长度需为16字节（128位）
        var plaintext = tgmudid;

        // 确保密钥长度为16字节
        key = CryptoJS.enc.Utf8.parse(key);

        // 加密数据
        var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // 转换为十六进制字符串（bin2hex 类似操作）
        var encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

        tgmudid = encryptedHex;
      }

      let appId = "991701";
      let time = new Date().getTime().toString();

      let iosObjData = {
        appid: appId,
        agent: tgmudid,
        device_type: "android",
        game_id: game?.maiyou_gameid,
        remark: CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(`{"agent":"${tgmudid}"}`)
        ),
        signature_type: "v1",
        subpackage_type: "9917",
        timestamp: time,
        version: "",
        username: localStorage.getItem("username") || "",
        userid: localStorage.getItem("user_id") || 0,
        depart: tgmudid.length > 8 ? "info" : "",
      };

      // 获取对象的键并排序
      const sortedKeys = Object.keys(iosObjData).sort();
      // 使用排序后的键生成一个新的对象
      const sortedObj = {};
      sortedKeys.forEach((key) => {
        sortedObj[key] = iosObjData[key];
      });

      let sign = `${appId}${JSON.stringify(
        sortedObj
      )}4C5CBC70C5FF6742D133D93D730D4C96`;

      general.axios
        .post("/handle/subpackage", {
          ...iosObjData,
          sign: encodeAndHash(sign),
        })
        .then((res) => {
          console.log("下载---", res);

          let resInfo = res.data;
          if (resInfo.code !== 200) {
            Dialog.close();
            data.interval = undefined;

            return Dialog.error(resInfo.msg);
          }

          Dialog.close();

          let data = res.data.data;
          if (data.subpackageDownload) {
            window.location.href = data.subpackageDownload;
          }
        })
        .catch((error) => {
          data.interval = undefined;

          Dialog.close();
          Dialog.error(error);
        });
    }
  });

  const reserve = () => {
    setGame({ ...game, is_reserved: !game.is_reserved });
    general.axios
      .post(game.is_reserved ? "/cancelReserveGame" : "/reserveGame", {
        id: game?.maiyou_gameid,
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          Dialog.success("操作成功");
        }
      })
      .catch((err) => {
        Dialog.error("reserveGame " + err.message);
      });
  };

  const data = useReactive({
    auto: undefined,
    super: undefined,
    intercept: undefined,
    interceptCall: undefined,
    btns: undefined,
    interceptCallback: undefined,
  });

  useEffect(async () => {
    console.log("有auto啦啦啦啦啦啦啦啦~~~~");
    if (params.auto && data.auto === undefined) {
      console.log("设置auto啦啦啦啦啦啦啦啦~~~~");
      let uri = new URI(window.location.hash.substring(1));
      let query = uri.query(true);

      if (query.appDownload) {
        setUdid(query.udid);
      }

      if (!localStorage.getItem("tgm_uuid")) {
        Dialog.loading();
        await getUuidCallback();
        Dialog.close();

        if (localStorage.getItem("tgm_uuid")) {
          behaviorTrigger("custom");
        }
        data.auto = params.auto;
      } else {
        if (localStorage.getItem("tgm_uuid")) {
          behaviorTrigger("custom");
        }
        data.auto = params.auto;
      }
    }
  }, [params.auto, data.auto]);

  useEffect(() => {
    data.btns =
      game?.cloud_support_type === "sq" &&
      game?.cloudDisabled === 0 &&
      (mobileDetect.is("iPhone") ||
        mobileDetect.is("iPad") ||
        mobileDetect.match("Mac OS") ||
        navigator.userAgent == "app" ||
        navigator.userAgent == "uniapp");
  }, [game?.cloudDisabled]);

  // 拦截
  const intercept = (type, callback) => {
    console.log(type, callback);
    data.intercept = type;
    data.interceptCallback = callback;
  };

  const [udidSync, setUdidSync] = useLocalStorageState(
    `udid_sync_${dayjs().format("YYYYMMDDHHmm")}`
  );

  // 节流effect
  useThrottleEffect(async () => {
    console.log("进入详情页面");
    if (udidSync) {
      return;
    }
    setUdidSync(true);

    if (udid && !config.udid_usable) {
      await general.axios
        .post("/base/common/getConfig")
        .then(({ data: resData }) => {
          let { status, data } = resData;
          if (status.succeed) {
            setConfig({ ...config, udid_usable: data.udid_usable });
          }
        })
        .catch((error) => console.error(error));
    }
    setUdidSync(true);
  }, [key, udid, config.udid_usable, udidSync]);

  const [tipsed, setTipsed] = useLocalStorageState(
    `game_tips_${props.query.id || props.query.mid}_${dayjs().format("YYYYMM")}`
  );

  const [standalone] = useLocalStorageState("standalone");

  const encryptWithPublicKey = (message, publicKey) => {
    // 创建 JSEncrypt 实例
    const encrypt = new JSEncrypt();

    // 设置公钥
    encrypt.setPublicKey(publicKey);

    // 加密消息
    const encrypted = encrypt.encrypt(message);

    return encrypted;
  };

  // 免签名下载
  const [giftShow, setGiftShow] = useState(false);
  const [isClick, setIsClick] = useState(true);
  const signingDownload = async (num) => {
    console.log("下载游戏免签名");

    if (!localStorage.getItem("tgm_uuid")) {
      Dialog.loading();
      await getUuidCallback();
      Dialog.close();

      if (localStorage.getItem("tgm_uuid")) {
        behaviorTrigger("custom");
        if (!num) {
          noSignFun();
        }
      } else {
        if (!num) {
          noSignFun();
        }
      }
    } else {
      if (localStorage.getItem("tgm_uuid")) {
        behaviorTrigger("custom");
      }
      if (!num) {
        noSignFun();
      }
    }
  };

  const noSignFun = () => {
    // 检测是否在Safari浏览器中打开
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
      Dialog.loading();

      const route = URI(location.pathname + location.search + location.hash)
        .addQuery("agent", agent)
        .addQuery("key", key)
        .addQuery("userid", localStorage.getItem("user_id") || "")
        .addQuery("username", localStorage.getItem("username") || "")
        .addQuery("token", localStorage.getItem("token") || "")
        .addQuery("standalone", true)
        .addQuery("appDownload", true)
        .addQuery("bd_vid", localStorage.getItem("bd_vid"))
        .addQuery("uuid", localStorage.getItem("tgm_uuid"))
        .toString();

      console.info(
        "app下载---------------",
        URI(window.location.href).hash(route).toString()
      );
      uni.postMessage({
        data: {
          routeUrl: URI(window.location.href).hash(route).toString(),
        },
      });

      setTimeout(() => {
        let uri = new URI(window.location.hash.substring(1));
        let query = uri.query(true);
        query.agent = agent;
        query.key = key;
        query.userid = localStorage.getItem("user_id") || "";
        query.username = localStorage.getItem("username") || "";
        query.token = localStorage.getItem("token") || "";
        query.standalone = true;
        query.appDownload = true;
        query.bd_vid = localStorage.getItem("bd_vid");
        query.uuid = localStorage.getItem("tgm_uuid");
        console.log("app下载query---------------", query);

        uri.query(query);
        console.warn(uri.toString());
        Dialog.close();
        history.replace(uri.toString());
      }, 1000);

      return;
    }

    if (isClick) {
      setIsClick(false);
      setGiftShow(true);
      // Dialog.loading();

      let data = {};
      data.game_id = game?.maiyou_gameid || gameData?.maiyou_gameid;
      data.app_id = game?.game_id || gameData?.game_id;
      data.ag = localStorage.getItem("agent") || "x7z1";

      if (localStorage.getItem("tgm_uuid")) {
        data.ag = localStorage.getItem("tgm_uuid")
          ? data.ag + "~" + localStorage.getItem("tgm_uuid")
          : data.ag;

        // 密钥和明文
        var key = "1234567890123456"; // 密钥长度需为16字节（128位）
        var plaintext = data.ag;

        // 确保密钥长度为16字节
        key = CryptoJS.enc.Utf8.parse(key);

        // 加密数据
        var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // 转换为十六进制字符串（bin2hex 类似操作）
        var encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
        console.log("Encrypted (Hex):", encryptedHex);

        data.ag = encryptedHex;
      }

      general.axios
        .get("/base/game/selfsigndownload", {
          params: {
            ...data,
          },
        })
        .then((res) => {
          console.log("下载免签名包体", res);
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (localStorage.getItem("tgm_uuid")) {
            behaviorTrigger("downloadpkg");
          }

          window.location.href = data.url;
          // setTimeout(() => {
          //   Dialog.close();
          // }, 3000);
          setIsClick(true);
        })
        .catch((err) => {
          setIsClick(true);
          // Dialog.close();
          // Dialog.error('getGameDetail ' + err.message);
        });
    }
  };

  // 点击至尊版下载
  const location = useLocation();
  const downloadGame = _.throttle(async (num) => {
    Dialog.loading();
    await getUdidVal();
    console.log("至尊版下载99999988888------");

    if (!localStorage.getItem("tgm_uuid")) {
      await getUuidCallback();
      Dialog.close();

      if (localStorage.getItem("tgm_uuid")) {
        behaviorTrigger("custom");
      }
    } else {
      Dialog.close();
      if (localStorage.getItem("tgm_uuid")) {
        behaviorTrigger("custom");
      }
    }

    if (num) return;

    // 检测是否在Safari浏览器中打开
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
      Dialog.loading();

      const route = URI(location.pathname + location.search + location.hash)
        .addQuery("agent", agent)
        .addQuery("key", key)
        .addQuery("userid", localStorage.getItem("user_id") || "")
        .addQuery("username", localStorage.getItem("username") || "")
        .addQuery("token", localStorage.getItem("token") || "")
        .addQuery("standalone", true)
        .addQuery("appDownload", true)
        .addQuery("bd_vid", localStorage.getItem("bd_vid"))
        .addQuery("uuid", localStorage.getItem("tgm_uuid"))
        .toString();

      console.info(
        "app下载---------------",
        URI(window.location.href).hash(route).toString()
      );
      uni.postMessage({
        data: {
          routeUrl: URI(window.location.href).hash(route).toString(),
        },
      });

      setTimeout(() => {
        let uri = new URI(window.location.hash.substring(1));
        let query = uri.query(true);
        query.agent = agent;
        query.key = key;
        query.userid = localStorage.getItem("user_id") || "";
        query.username = localStorage.getItem("username") || "";
        query.token = localStorage.getItem("token") || "";
        query.standalone = true;
        query.appDownload = true;
        query.bd_vid = localStorage.getItem("bd_vid");
        query.uuid = localStorage.getItem("tgm_uuid");
        console.log("app下载query---------------", query);

        uri.query(query);
        console.warn(uri.toString());
        Dialog.close();
        history.replace(uri.toString());
      }, 1000);

      return;
    }

    if (game?.specialized?.button_name) {
      Dialog.welfare(
        game.specialized.pop_title,
        <div
          dangerouslySetInnerHTML={{
            __html: game.specialized.content,
          }}
        ></div>,
        () => {
          console.log("前往领取");
          window.location.href = game.specialized.url;
        },
        () => {
          console.log("----------");
          if (game.specialized.is_show_down == 1) {
            if (data.btns && !tipsed) {
              setTipsed(true);
            }
            intercept("super", () => (data.super = true));
            spm(`game.${game?.maiyou_gameid}.download`);
          }
        },
        {
          btn: "前往领取",
          btnDown: game.specialized.is_show_down == 1 ? "直接下载" : "",
        }
      );
    } else {
      if (data.btns && !tipsed) {
        setTipsed(true);
      }
      intercept("super", () => (data.super = true));
      spm(`game.${game?.maiyou_gameid}.download`);
    }
  }, 3000);

  let [topHeight, setTopHeight] = useState(100);
  const navRef = useRef();
  const barRef = useRef();

  const [serverShow, setServerShow] = useState(false);
  const [jurisdictionShow, setJurisdictionShow] = useState(false);

  const desktopTipsBranch = (
    (navigator.userAgent.match(/(edgios)/i) || {})[0] || ""
  ).toLowerCase();

  return (
    <>
      <div
        className={`game-detail ${
          navigator.userAgent == "app" || navigator.userAgent == "uniapp"
            ? "no-video"
            : ""
        }`}
        ref={ref}
      >
        {game && !props.loading ? (
          <>
            <div className="game-body">
              <div
                className="status-bar-background statusBar"
                ref={barRef}
                style={{
                  backgroundImage: colour
                    ? `linear-gradient(${colour},${colour})`
                    : "linear-gradient(rgba(204, 204, 204, 0.95),rgba(204, 204, 204, 0))",
                }}
              />

              <div
                className="game-nav fixed"
                ref={navRef}
                style={colour ? { backgroundColor: colour } : null}
              >
                <div
                  className="game-nav-item leftIcon"
                  style={{ color: colour == "#202737" ? "#fff" : "#000" }}
                  onClick={() => general.history.goBack()}
                >
                  <LeftOutlined
                    style={{ fontSize: "18px", paddingTop: "5px" }}
                  />
                </div>

                {router.map((item, index) => {
                  let count = 0;
                  let show = true;
                  if (item.id == "comment") {
                    count = game?.game_comment_num;
                  } else if (item.id == "tool") {
                    count = game?.cloud_hanging_up?.num || 0;

                    if (count < 1) {
                      show = false;
                    }
                  }

                  return (
                    show && (
                      <div
                        key={index}
                        className={
                          "game-nav-item game-nav-" +
                          item.id +
                          " " +
                          (routerIndex == index ? "active" : "")
                        }
                        style={{ color: colour == "#202737" ? "#fff" : "#000" }}
                        onClick={() => {
                          $("html,body").animate({ scrollTop: 0 }, 200);
                          setRouterIndex(index);
                        }}
                      >
                        <div className="text">
                          {item.name}
                          {count && count > 0 ? (
                            <div
                              className="count"
                              style={{
                                color: colour == "#202737" ? "#fff" : "#000",
                              }}
                            >
                              {count > 99 ? "99+" : count}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>

              <div
                className="game-main"
                style={{ paddingTop: topHeight + "px" }}
              >
                <Swiper
                  allowTouchMove={false}
                  onSwiper={setSwiper}
                  onSlideChange={(swiper) => setRouterIndex(swiper.activeIndex)}
                >
                  {router.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className={
                          "game-main-item game-main-" +
                          item.id +
                          " " +
                          (routerIndex === index ? "active" : "")
                        }
                      >
                        <Active active={props.active && routerIndex === index}>
                          <item.component
                            active={props.active && routerIndex === index}
                            game={
                              game
                                ? game
                                : { game: { game_id: props.query.id } }
                            }
                            setGame={setGame}
                            load={load}
                            setServerShow={setServerShow}
                            setJurisdictionShow={setJurisdictionShow}
                          />
                        </Active>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="game-operate width">
              <Tips game={game} />
              {/* 非线上环境及测试环境显示 速启云 游上云 企业签*/}
              {/* {!prod ? (
                <>
                  <div className="test">
                    <NavLink
                      className="btn"
                      onClick={() => playCloudGame("test", "sq")}
                    >
                      速启云
                    </NavLink>
                    <NavLink
                      className="btn"
                      onClick={() => playCloudGame("test", "ys")}
                    >
                      游上云
                    </NavLink>
                    {mobileDetect.is("iPhone") || mobileDetect.is("iPad") ? (
                      <>
                        <div className="btn" onClick={download}>
                          企业签
                          {game.game_size?.ios_size && (
                            <div className="size">
                              {game.game_size?.ios_size}
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null} */}
              <div className="container">
                <NavLink
                  className={
                    "favorite-container " + (game.is_collected ? "active" : "")
                  }
                  onClick={favorite}
                >
                  <img
                    src="/resources/game/notLike.png"
                    alt=""
                    className="icon favorite"
                  />
                  <img
                    src="/resources/game/like.png"
                    alt=""
                    className="icon favorited"
                  />
                  收藏
                </NavLink>
                <div className={`btns ${data.btns ? "multiple" : ""}`}>
                  {game.game_species_type == 3 ? (
                    <>
                      <NavLink
                        className="btn"
                        user={true}
                        escape={true}
                        to={`${game.game_url.android_url}&screen=${game.screen_orientation}`}
                        iframe={{ title: game.game_name }}
                      >
                        开始玩
                      </NavLink>
                    </>
                  ) : (
                    <>
                      {/* iOS */}
                      {mobileDetect.is("iPhone") ||
                      mobileDetect.is("iPad") ||
                      mobileDetect.match("Mac OS") ||
                      navigator.userAgent == "app" ||
                      navigator.userAgent == "uniapp" ? (
                        <>
                          {/* 云玩 */}
                          {game?.cloud_support_type === "sq" &&
                          game?.cloudDisabled === 0 ? (
                            <>
                              <NavLink
                                className="btn btn-cloud"
                                user={true}
                                onClick={() => {
                                  intercept("cloud", playCloudGame);
                                  spm(`game.${game?.maiyou_gameid}.cloud`);
                                }}
                              >
                                <div className="icon">
                                  <img
                                    src="/resources/game/cloud.png"
                                    alt="云玩"
                                  />
                                </div>
                                <div className="text">云玩</div>
                              </NavLink>
                            </>
                          ) : null}

                          {/* 免签名下载 */}
                          {downloadSigning && (
                            <>
                              {(() => {
                                // 是否需要登录才可下载
                                let user =
                                  config.ios_guest_downloadable !== true;
                                if (
                                  user &&
                                  !navigator.standalone &&
                                  !standalone
                                ) {
                                  user = !udid;
                                }
                                return (
                                  <NavLink
                                    className="btn"
                                    // user={
                                    //   user && agent !== "3rem" && !needLogin
                                    // }
                                    user={false}
                                    onClick={(e) => {
                                      console.log("免签名下载999999999999999");

                                      if (
                                        user &&
                                        agent !== "3rem" &&
                                        !needLogin &&
                                        !general.isLogin()
                                      ) {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        signingDownload(9);

                                        history.push(
                                          URI("/login")
                                            .addQuery(
                                              "callback",
                                              location.pathname +
                                                location.search +
                                                location.hash
                                            )
                                            .toString()
                                        );
                                        return;
                                      }

                                      signingDownload();
                                      spm(
                                        `game.${game?.maiyou_gameid}.download`
                                      );
                                    }}
                                  >
                                    <span className="text">下载(推荐)</span>
                                    {game.game_size?.ios_size && (
                                      <div className="size">
                                        {game.game_size?.ios_size}
                                      </div>
                                    )}
                                  </NavLink>
                                );
                              })()}
                            </>
                          )}

                          {/* 至尊版下载 */}
                          {iosBtnShow && game.downButton?.Super && (
                            <>
                              {(() => {
                                let user =
                                  config.ios_guest_downloadable !== true;
                                if (
                                  user &&
                                  !navigator.standalone &&
                                  !standalone
                                ) {
                                  user = !udid;
                                }
                                return (
                                  <NavLink
                                    className="btn super-btn"
                                    // user={
                                    //   user && agent !== "3rem" && !needLogin
                                    // }
                                    user={false}
                                    onClick={(e) => {
                                      console.log("超级签下载999999999999999");

                                      if (
                                        user &&
                                        agent !== "3rem" &&
                                        !needLogin &&
                                        !general.isLogin()
                                      ) {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        downloadGame(9);

                                        history.push(
                                          URI("/login")
                                            .addQuery(
                                              "callback",
                                              location.pathname +
                                                location.search +
                                                location.hash
                                            )
                                            .toString()
                                        );
                                        return;
                                      }

                                      downloadGame();
                                    }}
                                  >
                                    {game.specialized?.button_name ? (
                                      <>
                                        <span className="text">
                                          {game.specialized.button_name}
                                        </span>
                                        {game.game_size?.ios_size && (
                                          <div className="size">
                                            {game.game_size?.ios_size}
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {/* {data.btns && !tipsed && (
                                          <div className="tips">
                                            下载客户端更稳定
                                          </div>
                                        )} */}
                                        <div className="text">至尊版下载</div>
                                        {game.game_size?.ios_size && (
                                          <div className="size">
                                            {game.game_size?.ios_size}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </NavLink>
                                );
                              })()}
                            </>
                          )}

                          {/* 首发提醒 */}
                          {game.status == 0 && (
                            <>
                              <NavLink
                                className="btn btn-reserve"
                                onClick={reserve}
                              >
                                {game.is_reserved ? (
                                  <h1>取消提醒</h1>
                                ) : (
                                  <>
                                    <h2>开启首发提醒</h2>
                                    <p>
                                      {dayjs
                                        .unix(game.starting_time)
                                        .format("MM-DD HH:mm")}
                                      首发
                                    </p>
                                  </>
                                )}
                              </NavLink>
                            </>
                          )}
                        </>
                      ) : (
                        // 安卓
                        <>
                          {game.specialized?.button_name ? (
                            <div
                              className="btn"
                              onClick={() => {
                                Dialog.welfare(
                                  game.specialized.pop_title,
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: game.specialized.content,
                                    }}
                                  ></div>,
                                  () => {
                                    console.log("前往领取");

                                    window.location.href = game.specialized.url;
                                  },
                                  () => {
                                    console.log("----------");
                                    if (game.specialized.is_show_down == 1) {
                                      downloadAnd();
                                      spm(
                                        `game.${game?.maiyou_gameid}.download`
                                      );
                                    }
                                  },
                                  {
                                    btn: "前往领取",
                                    btnDown:
                                      game.specialized.is_show_down == 1
                                        ? "直接下载"
                                        : "",
                                  }
                                );
                              }}
                            >
                              <span className="text">
                                {game.specialized.button_name}
                              </span>
                            </div>
                          ) : (
                            <div
                              className="btn"
                              onClick={() => {
                                downloadAnd();
                                spm(`game.${game?.maiyou_gameid}.download`);
                              }}
                            >
                              <span className="text">下载</span>
                              <div className="size">
                                {game.game_size?.android_size}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <NavLink
                  to={"/comment/submit?id=" + game.comment_topic_id}
                  className="appraise"
                >
                  <img
                    src="/resources/game/comment.png"
                    alt=""
                    className="icon"
                  />
                  点评
                </NavLink>
              </div>
            </div>
          </>
        ) : (
          <div className="game-skeleton">
            <div className="a" />
            <div className="b">
              <img src={imgPlaceholder.default} />
            </div>
          </div>
        )}

        {data.auto && game ? (
          <>
            <Require
              reject={() => (data.auto = false)}
              type={data.auto === "desktop" ? "cloud" : data.auto}
            >
              <Auto
                game={game}
                type={data.auto === "desktop" ? "cloud" : data.auto}
                close={() => (data.auto = false)}
                callback={
                  data.auto === "cloud" || data.auto === "desktop"
                    ? () => playCloudGame("auto", null, data.auto === "desktop")
                    : () => (data.super = true)
                }
                superGo={() => (data.super = true)}
                cloud={() => playCloudGame("auto")}
              />
            </Require>
          </>
        ) : null}
        {data.super ? (
          <>
            <Require reject={() => (data.super = false)}>
              <Super game={game} close={() => (data.super = false)} />
            </Require>
          </>
        ) : null}
        {data.intercept ? (
          <>
            <Require
              reject={() => (data.intercept = false)}
              type={data.intercept}
            >
              <Intercept
                game={game}
                type={data.intercept}
                close={() => (data.intercept = false)}
                callback={data.interceptCallback}
                superGo={() => (data.super = true)}
                cloud={() => playCloudGame("confirm")}
              />
            </Require>
          </>
        ) : null}
      </div>

      {game && !props.loading && (
        <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
          <div className="gift-detail">
            <div className="gift-mask" />
            <div
              className="gift-container"
              style={{
                height: "auto",
                maxHeight: "100vh",
              }}
            >
              <div className="gift-top" style={{ borderBottom: "none" }}>
                <div className="down-title"></div>
                <img
                  style={{ height: "16px", padding: " 0 10px" }}
                  src="/resources/game/close.png"
                  alt=""
                  onClick={() => {
                    setGiftShow(false);
                  }}
                />
              </div>

              <div className="nosign-game-tips">
                <p>游戏打包中请稍后，待弹出安装后即可安装！</p>
                <p style={{ color: "#FF3C3C" }}>
                  游戏安装完成后需根据以下步骤授权
                </p>
              </div>

              <div
                className="gift-main nosign-game"
                style={{ marginTop: "10px" }}
              >
                <div className="down-desc" style={{ paddingBottom: "16px" }}>
                  <p className="down-every">
                    1.打开设置，选择通用-VPN与设备管理
                  </p>

                  <div className="nosign-tips-img">
                    <img src="/resources/download/sign01.png" alt="" />
                    <img src="/resources/download/sign02.png" alt="" />
                  </div>

                  <p className="down-every" style={{ marginTop: "22px" }}>
                    2.找到游戏对应的授权，信任后即可
                  </p>

                  <div className="nosign-tips-img">
                    <img src="/resources/download/sign03.png" alt="" />
                    <img src="/resources/download/sign04.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}

      <CSSTransition in={serverShow} timeout={250} unmountOnExit={true}>
        <div className="server-region-content">
          <div
            className="server-mask"
            onClick={() => {
              setServerShow(false);
            }}
          />
          <div className="server-main">
            <div className="gift-top">
              <div className="down-title" style={{ paddingLeft: "46px" }}>
                开服表
              </div>
              <img
                style={{ width: "16px", height: "16px" }}
                src="/resources/game/close.png"
                alt=""
                onClick={() => {
                  setServerShow(false);
                }}
              />
            </div>
            <Server game={game} />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={jurisdictionShow} timeout={250} unmountOnExit={true}>
        <div className="server-region-content">
          <div
            className="server-mask"
            onClick={() => {
              setJurisdictionShow(false);
            }}
          />
          <div className="server-main">
            <div style={{ position: "relative", paddingBottom: "16px" }}>
              <div className="gift-top">
                <div className="down-title">权限信息</div>
              </div>
              <div
                className="jur-main"
                dangerouslySetInnerHTML={{
                  __html: game?.game_control_info?.authority_info,
                }}
              ></div>
              <div className="jur-btn">
                <div
                  className="btn-main"
                  onClick={() => {
                    setJurisdictionShow(false);
                  }}
                >
                  我知道了
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      {/* 
      <div style={{ paddingBottom: "500px" }}>
        {window.navigator.standalone}-----
        {standalone}
      </div> */}

      {/* {params.appDownload &&
        !params.udid &&
        (!window.navigator.standalone || !standalone) && (
          <div className="desktop-tips detail-desktop">
            <div className="container">
              <img
                src={`/resources/desktop-tips-1${
                  desktopTipsBranch ? `-${desktopTipsBranch}` : ""
                }.png`}
                alt=""
                className="img1"
              />
              <img
                src="/resources/desktop-tips-2.png"
                alt=""
                className="img2"
              />
              <div className="body">
                <div className="icon">
                  <Image src={app.appicon} alt="" />
                </div>
                <div className="info">
                  <div className="name">{app.appname}</div>
                  <div className="url">{window.location.href}</div>
                </div>
              </div>
              <img
                src="/resources/desktop-tips-3.png"
                alt=""
                className="img3"
              />
              <img
                src="/resources/desktop-tips-4.png"
                alt=""
                className="img4"
              />
              <div className="text4">
                回到手机桌面，点击【<span>{app.appname}</span>
                】即可进入
              </div>
            </div>
          </div>
        )} */}
    </>
  );
};
