import { useCallback, useEffect, useRef, useState } from "react";
import {
  QuestionCircleOutlined,
  SyncOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSize, useLocalStorageState, useReactive, useScroll } from "ahooks";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import $ from "jquery";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { sysUrl } from "../../../config";
import { NavLink } from "../../../lib/Navigation";

export default (props) => {
  let [game, setGame] = useState({});
  const openService = useReactive({
    info: {
      dayTime: "",
      serviceName: "",
    },
  });
  const [init, setInit] = useState();
  useEffect(() => {
    game = props.game;
    setGame(props.game);

    let list = props.game.kaifu_info;
    if (list && list.length > 0) {
      console.log(list);
      // 获取当前时间 若第一项为今日日期  则显示今天 00:00(截取时间)
      let nowDay = dayjs().format("MM.DD");
      let firstTime = dayjs
        .unix(game.game_control_info?.server_info?.starttime)
        .format("MM.DD HH:mm");
      console.log(nowDay, firstTime);

      if (nowDay === firstTime.split(" ")[0]) {
        openService.info.dayTime = "今天 " + firstTime.split(" ")[1];
      } else {
        openService.info.dayTime = firstTime;
      }
      openService.info.serviceName =
        game.game_control_info?.server_info?.kaifuname;
    }

    setInit(true);
  }, [props.game]);

  const [inlineBenefitMore, setInlineBenefitMore] = useState(true);
  const [inlineBenefitMored, setInlineBenefitMored] = useState(false);
  const inlineBenefitBodyRef = useRef();
  const inlineBenefitEntityRef = useRef();
  const inlineBenefitBodySize = useSize(inlineBenefitBodyRef);
  const inlineBenefitEntitySize = useSize(inlineBenefitEntityRef);
  useEffect(() => {
    !inlineBenefitMored &&
      setWelfareMore(
        inlineBenefitEntitySize?.height > inlineBenefitBodySize?.height
      );
  }, [inlineBenefitBodySize, inlineBenefitEntitySize]);

  const [welfareMore, setWelfareMore] = useState(true);
  const [welfareMored, setWelfareMored] = useState(false);
  const welfareBodyRef = useRef();
  const welfareEntityRef = useRef();
  const welfareBodySize = useSize(welfareBodyRef);
  const welfareEntitySize = useSize(welfareEntityRef);
  useEffect(() => {
    !welfareMored &&
      setWelfareMore(welfareEntitySize?.height > welfareBodySize?.height);
  }, [welfareBodySize, welfareEntitySize]);

  const [rebateMore, setRebateMore] = useState(true);
  const [rebateMored, setRebateMored] = useState(false);
  const rebateBodyRef = useRef();
  const rebateEntityRef = useRef();
  const rebateBodySize = useSize(rebateBodyRef);
  const rebateEntitySize = useSize(rebateEntityRef);
  useEffect(() => {
    !rebateMored &&
      setRebateMore(rebateEntitySize?.height > rebateBodySize?.height);
  }, [rebateBodySize, rebateEntitySize]);

  const [manufacturerMore, setManufacturerMore] = useState(true);
  const [manufacturerMored, setManufacturerMored] = useState(false);
  const manufacturerBodyRef = useRef();
  const manufacturerEntityRef = useRef();
  const manufacturerBodySize = useSize(manufacturerBodyRef);
  const manufacturerEntitySize = useSize(manufacturerEntityRef);
  useEffect(() => {
    !manufacturerMored &&
      setManufacturerMore(
        manufacturerEntitySize?.height > manufacturerBodySize?.height
      );
  }, [manufacturerBodySize, manufacturerEntitySize]);

  const imgRef = useCallback((node) => {
    if (node) {
      let container = $(node).find(".container")[0];
      var startScroll, touchStart, touchCurrent;
      node.addEventListener(
        "touchstart",
        (e) => {
          startScroll = container.scrollLeft;
          touchStart = e.targetTouches[0].pageX;
        },
        { passive: false }
      );
      node.addEventListener(
        "touchmove",
        (e) => {
          touchCurrent = e.targetTouches[0].pageX;
          var touchesDiff = touchCurrent - touchStart;
          var onlyScrolling =
            container.scrollWidth > container.offsetWidth &&
            ((touchesDiff < 0 && startScroll === 0) ||
              (touchesDiff > 0 &&
                startScroll ===
                  container.scrollWidth - container.offsetWidth) ||
              (startScroll > 0 &&
                startScroll < container.scrollWidth - container.offsetWidth));
          if (onlyScrolling) {
            e.stopPropagation();
          }
        },
        { passive: false }
      );
    }
  }, []);

  const recommend = () => {
    Dialog.loading();
    general.axios
      .post("/base/newindex/GameLike", { game_id: game.game_id })
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        props.setGame((game) => ({ ...game, recommend_info: data.list }));
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  const [muted, setMuted] = useLocalStorageState("game-video-muted", {
    defaultValue: true,
  });
  const [player, setPlayer] = useState();

  const video = useReactive({});
  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
  };
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
  };
  useEffect(() => {
    if (player) {
      if (props.active && video.activePause) {
        player.play();
      }
      if (!props.active) {
        if (video.play) {
          player.pause();
          video.play = true;
        } else {
          player.pause();
        }
        if (video.play) {
          video.activePause = true;
        }
      }
    }
  }, [props.active, player]);

  let [giftCode, setGiftCode] = useState("");

  const menuRef = useRef(null);
  const menuScroll = useScroll(menuRef);
  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (menuRef.current && !init) {
      setTimeout(() => {
        $(menuRef.current).css({
          scrollLeft: 0,
        });

        $(scrollbarRef.current).css({
          width: "38%",
          left: 0,
        });
      }, 200);

      // setTimeout(() => {
      //   $(menuRef.current).animate(
      //     { scrollLeft: menuRef.current?.offsetWidth || 0 },
      //     1000,
      //     null,
      //     () => {
      //       $(menuRef.current).animate({ scrollLeft: 0 }, 1000);
      //     }
      //   );
      // }, 250);
    }
  }, [init]);

  useEffect(() => {
    if (menuRef.current && menuScroll && scrollbarRef.current) {
      $(scrollbarRef.current).css({
        left: `${
          ((menuScroll.left + $(menuRef.current).width() / 2) /
            menuRef.current.scrollWidth) *
          100
        }%`,
      });

      $(scrollbarRef.current).css({
        width: `${
          ($(menuRef.current).width() / menuRef.current.scrollWidth) * 100
        }%`,
        // width: "38%",
      });
    }
  }, [menuScroll]);

  // 领取648
  const getSixGift = () => {
    if (game.game_control_info?.is_have === 1) {
      general.axios
        .post("/base/game/receive", {
          id: game.game_control_info?.gift_id,
        })
        .then((res) => {
          console.log("领取648-----", res.data);
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }

          giftCode = data;
          setGiftCode(data);
          console.log("55555555555555555555");
          // game.game_control_info.is_have = 2;
          props.setGame({
            ...game,
            game_control_info: {
              ...game.game_control_info,
              is_have: 2,
            },
          });

          Dialog.alert(
            "领取成功",
            <div>
              <div className="gift-pack-code">
                <p>
                  礼包码：<span className="code">{giftCode}</span>
                </p>
                <p style={{ marginTop: "3px" }}>(前往游戏内使用)</p>
              </div>
              <div className="gift-pack-code-font">游戏→我的礼包，查看使用</div>
            </div>,
            () => {
              Dialog.info("复制成功");
            },
            {
              btn: (
                <CopyToClipboard text={giftCode}>
                  <span className="copy">复 制</span>
                </CopyToClipboard>
              ),
            }
          );
        })
        .catch((err) => {
          //Dialog.error('getTradeList ' + err.message);
        });
    } else {
      Dialog.alert(
        "领取成功",
        <div>
          <div className="gift-pack-code">
            <p>
              礼包码：
              <span className="code">
                {game.game_control_info?.giftCode || giftCode}
              </span>
            </p>
            <p style={{ marginTop: "3px" }}>(前往游戏内使用)</p>
          </div>
          <div className="gift-pack-code-font">游戏→我的礼包，查看使用</div>
        </div>,
        () => {
          Dialog.info("复制成功");
        },
        {
          btn: (
            <CopyToClipboard
              text={game.game_control_info?.giftCode || giftCode}
            >
              <span className="copy">复 制</span>
            </CopyToClipboard>
          ),
        }
      );
    }
  };

  return (
    <div className="game-index">
      <div className="game-imgs" ref={imgRef}>
        <div className="container" ref={menuRef}>
          <div className="img">
            {game.video_url && (
              <div className="video-main">
                <div className="game-video">
                  <video
                    autoPlay
                    muted
                    controls
                    webkit-playsinline="true"
                    playsInline={true}
                    loop
                    src={game.video_url}
                    type="video/mp4"
                  ></video>

                  {/* <Player
                    autoPlay={true}
                    playsInline={true}
                    muted={muted}
                    onVolumeChange={(e) => setMuted(e.target.muted)}
                    fluid={false}
                    width="100%"
                    height="100%"
                    ref={(player) => setPlayer(player)}
                    onPlaying={onPlaying}
                    onPause={onPause}
                  >
                    <source src={game.video_url} />
                  </Player> */}
                </div>
              </div>
            )}

            {game.game_ur_list?.map((img, index, imgs) => (
              <Link
                key={index}
                to={"/img?imgs=" + imgs + "&img=" + index}
                className="bannerImg"
              >
                {/* <div className="top-shadow"></div> */}
                <Image src={img} alt="" />
                {/* <div className="bottom-shadow"></div> */}
              </Link>
            ))}
          </div>

          <div className="scrollbar">
            <div className="thumb" ref={scrollbarRef} />
          </div>
        </div>
      </div>

      <div className="game-info-content">
        <div className="game-info">
          <div className="icon">
            <Image src={game.game_image?.thumb} />
            {game.discount && game.discount < 1 && (
              <div className="discount">
                <span style={{ fontSize: "0.24rem", fontWeight: "bold" }}>
                  {parseFloat((game.discount * 10).toFixed(2))}
                </span>
                <span style={{ fontSize: "0.2rem" }}>折</span>
              </div>
            )}
          </div>

          <div className="info">
            <div className="name">
              {game.game_name_main || game.game_name}
              {game.game_name_branch ? (
                <div className="branch">{game.game_name_branch}</div>
              ) : null}
            </div>
            <div className="desc">
              {Number(game.game_download_num / 10000).toFixed(2)}w人在玩
              <div className="line"></div>
              {game.game_size?.ios_size}
            </div>
            {/* <div className="tags">
              {game.game_desc?.split("+")?.map((item, index) => (
                <div key={index} className="tag">
                  {item}
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="game-tag">
          {game.game_classify_name?.map((item) => (
            <div key={item.id} className="tag-every">
              <NavLink
                user={false}
                to={`${sysUrl}/home/actcloudhook/game?id=${item.id}&type=${item.type}&device=10`}
                iframe={{ title: item.tagname }}
              >
                {item.tagname}&gt;
              </NavLink>
            </div>
          ))}

          {game.cloud_hanging_up?.list?.map((item) => (
            <div key={item.id} className="tag-every">
              <NavLink
                user={false}
                to={`${sysUrl}/home/actcloudhook/game?id=${item.id}&type=${item.type}&device=10`}
                iframe={{ title: item.title }}
              >
                {item.title}&gt;
              </NavLink>
            </div>
          ))}
        </div>

        <div className="game-tab">
          <div
            className="tab-every"
            style={{
              display:
                game.kaifu_info && game.kaifu_info.length > 0
                  ? "unset"
                  : "flex",
              alignItems: "center",
            }}
            onClick={() => {
              props.setServerShow(true);
            }}
          >
            <div
              className="every-top"
              style={{
                marginRight:
                  game.kaifu_info && game.kaifu_info.length > 0 ? "0" : "5px",
                alignItems: "center",
              }}
            >
              {game.kaifu_info && game.kaifu_info.length > 0
                ? openService.info?.dayTime
                : "动态开服"}
            </div>
            <div
              className="every-bottom"
              style={{
                marginTop:
                  game.kaifu_info && game.kaifu_info.length > 0 ? "5px" : "0",
                alignItems: "center",
              }}
            >
              {/* 以游戏内实际开服为准 */}
              {game.kaifu_info && game.kaifu_info.length > 0 ? (
                <span className="bottom-info">
                  {openService.info?.serviceName}
                </span>
              ) : (
                ""
              )}

              <RightOutlined />
            </div>
          </div>

          <div className="line"></div>

          <Link to={"/game/gift?id=" + game.game_id} className="tab-every">
            <div className="every-top">
              {(game.gift_bag_list?.length && game.gift_bag_list?.length) || 0}
            </div>
            <div className="every-bottom">
              礼包
              <RightOutlined />
            </div>
          </Link>

          <div className="line"></div>

          <Link to={"/game/voucher?id=" + game.game_id} className="tab-every">
            <div className="every-top">￥{game.voucher_info?.amount || 0}</div>
            <div className="every-bottom">
              代金券
              <RightOutlined />
            </div>
          </Link>

          <div className="line"></div>

          <Link
            to={`/game/trade?game_id=${game.game_id}`}
            className="tab-every"
          >
            <div className="every-top">{game.trad_num || 0}</div>
            <div className="every-bottom">
              精选交易
              <RightOutlined />
            </div>
          </Link>
        </div>

        {game.game_control_info?.is_have !== 0 && (
          <div className="game-648">
            <div className="left">
              <img src="/resources/game/card.png" alt="" />
              <span>648礼包</span>
            </div>
            <NavLink
              className="btn"
              user={true}
              onClick={() => {
                getSixGift();
              }}
            >
              {game.game_control_info?.is_have === 1 ? "领取" : "去使用"}
            </NavLink>
          </div>
        )}
      </div>

      <div className="game-explain">
        {game.iosProblemData && game.iosProblemData.length > 0 && (
          <div className="item itemThree">
            <div className="head">
              <div className="subject">
                <div className="title">IOS安装常见问题</div>
              </div>
            </div>

            <div className="questions-container">
              {game.iosProblemData.map((item) => (
                <Link
                  key={item.id}
                  to={`/question?id=${item.id}`}
                  className="questions-every"
                >
                  <div className="title">{item.title}</div>
                  <img
                    src="/resources/game/right.png"
                    alt=""
                    style={{ width: "20px" }}
                  />
                </Link>
              ))}
            </div>
          </div>
        )}

        {game.game_feature_list && game.game_feature_list[0].content && (
          <div className="item">
            <div className="head">
              <div className="subject">
                <div className="title">上线福利</div>
                {game.game_control_info?.double_ended === 1 && (
                  <div
                    className="icon-tips"
                    onClick={() =>
                      Dialog.alert(
                        "温馨提示",
                        <div
                          style={{
                            padding: "0 11px",
                            fontSize: "0.24rem",
                            lineHeight: "22px",
                            color: "#666",
                            textAlign: "center",
                          }}
                        >
                          双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！
                        </div>,
                        () => {},
                        {
                          btn: "我知道了",
                        }
                      )
                    }
                  >
                    双端互通
                    <QuestionCircleOutlined style={{ marginLeft: "2px" }} />
                  </div>
                )}
              </div>
            </div>

            <div className="main-container">
              <div
                className={"body " + (inlineBenefitMored ? "more" : "")}
                ref={inlineBenefitBodyRef}
              >
                <div
                  className="entity ty-one"
                  ref={inlineBenefitEntityRef}
                  dangerouslySetInnerHTML={{
                    __html: game.game_feature_list
                      ? game.game_feature_list[0].content
                          .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2")
                          .replace(
                            /★/gi,
                            `<div style="width:11px;height:11px;margin-right: 3px;background-color:#fff;border-radius:50%;display: inline-block;border: 4px solid #FB7574;"></div>`
                          )
                      : null,
                  }}
                />
                {inlineBenefitMore && (
                  <div
                    className={"more " + (inlineBenefitMored ? "ed" : "")}
                    onClick={() => setInlineBenefitMored(!inlineBenefitMored)}
                  >
                    {inlineBenefitMored ? (
                      <img
                        src="/resources/game/top.png"
                        alt=""
                        style={{ width: "20px" }}
                      />
                    ) : (
                      <img
                        src="/resources/game/bottom.png"
                        alt=""
                        style={{ width: "20px" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {game.game_feature_list && game.game_feature_list[1].content && (
          <div className="item itemTwo">
            <div className="head">
              <div className="subject">
                <div className="title">返利</div>
                {/* 允许 1 手动   不允许 0 自动*/}
                {game.allow_rebate === 1 ? (
                  <>
                    <div
                      className="icon-tips"
                      onClick={() =>
                        Dialog.alert(
                          "温馨提示",
                          <div
                            style={{
                              padding: "0 11px",
                              fontSize: "0.24rem",
                              lineHeight: "22px",
                              color: "#666",
                              textAlign: "center",
                            }}
                          >
                            手动返利：充值达到返利标准后，需要通过APP手动申请返利，提交审核后-般24小时内到账
                          </div>,
                          () => {},
                          {
                            btn: "我知道了",
                          }
                        )
                      }
                    >
                      手动返利
                      <QuestionCircleOutlined style={{ marginLeft: "2px" }} />
                    </div>
                  </>
                ) : game.allow_rebate === 0 ? (
                  <>
                    <div
                      className="icon-tips"
                      onClick={() =>
                        Dialog.alert(
                          "温馨提示",
                          <div
                            style={{
                              padding: "0 11px",
                              fontSize: "0.24rem",
                              lineHeight: "22px",
                              color: "#666",
                              textAlign: "center",
                            }}
                          >
                            自动返利：充值达到返利标准后，无须手动申请，游戏一般为次日0点会自动发放到账！
                          </div>,
                          () => {},
                          {
                            btn: "我知道了",
                          }
                        )
                      }
                    >
                      自动返利
                      <QuestionCircleOutlined style={{ marginLeft: "2px" }} />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="icon-tips"
                      onClick={() =>
                        Dialog.alert(
                          "温馨提示",
                          <div
                            style={{
                              padding: "0 11px",
                              fontSize: "0.24rem",
                              lineHeight: "22px",
                              color: "#666",
                              textAlign: "center",
                            }}
                          >
                            无返利：代表此游戏没有充值返利
                          </div>,
                          () => {},
                          {
                            btn: "我知道了",
                          }
                        )
                      }
                    >
                      无返利
                      <QuestionCircleOutlined style={{ marginLeft: "2px" }} />
                    </div>
                  </>
                )}
              </div>
              {game.allow_rebate === 1 ? (
                <Link to={"/rebate?id=" + game.game_id} className="operate">
                  申请返利&gt;
                </Link>
              ) : null}
            </div>

            <div
              className={"body bodyTwo " + (rebateMored ? "more" : "")}
              ref={rebateBodyRef}
              style={{ marginTop: "8px" }}
            >
              <div
                className="entity"
                ref={rebateEntityRef}
                dangerouslySetInnerHTML={{
                  __html: game.game_feature_list
                    ? game.game_feature_list[1].content.replace(
                        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                        "$1<br>$2"
                      )
                    : null,
                }}
              />
              {rebateMore && (
                <div
                  className={"more " + (rebateMored ? "ed" : "")}
                  onClick={() => setRebateMored(!rebateMored)}
                >
                  {rebateMored ? (
                    <img
                      src="/resources/game/top.png"
                      alt=""
                      style={{ width: "20px" }}
                    />
                  ) : (
                    <img
                      src="/resources/game/bottom.png"
                      alt=""
                      style={{ width: "20px" }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="item itemThree">
          <div className="head">
            <div className="subject">
              <div className="title">游戏介绍</div>
            </div>
          </div>

          <div
            className={"body bodyThree " + (welfareMored ? "more" : "")}
            ref={welfareBodyRef}
            style={{ marginTop: "8px" }}
          >
            <div
              className="entity"
              ref={welfareEntityRef}
              dangerouslySetInnerHTML={{
                __html: (
                  game?.game_introduce_main || game?.game_introduce
                )?.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2"),
              }}
            />
            {welfareMore && (
              <div
                className={"more " + (welfareMored ? "ed" : "")}
                onClick={() => setWelfareMored(!welfareMored)}
              >
                {welfareMored ? (
                  <img
                    src="/resources/game/top.png"
                    alt=""
                    style={{ width: "20px" }}
                  />
                ) : (
                  <img
                    src="/resources/game/bottom.png"
                    alt=""
                    style={{ width: "20px" }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {game.vipprice ? (
        <>
          <Link
            to={`/game/vip?game=${game.game_name_main}`}
            onClick={() => {
              localStorage.setItem("vipWatchContent", game.vipprice);
            }}
          >
            <div className="game-vip">
              <div className="vip-left">
                <img src="/resources/game/vip.png" alt="" />
                <div className="title">《{game.game_name_main}》VIP表</div>
              </div>

              <img
                src="/resources/game/right.png"
                alt=""
                style={{ width: "20px" }}
              />
            </div>
          </Link>
        </>
      ) : null}

      <div className="game-manufacturer">
        <div
          className={"body bodyFour " + (manufacturerMored ? "more" : "")}
          ref={manufacturerBodyRef}
        >
          <div className="entity" ref={manufacturerEntityRef}>
            <div className="every">
              <p className="title">厂商信息</p>
              <p className="font">{game.game_control_info?.company_name}</p>
            </div>
            <div className="every">
              <p className="title">当前版本</p>
              <p className="font">{game.game_control_info?.version?.ios}</p>
            </div>
            {game.game_control_info?.filings_number && (
              <div className="every">
                <p className="title">软件备案</p>
                <p className="font">{game.game_control_info?.filings_number}</p>
              </div>
            )}

            {game.game_control_info?.authority_info ||
            game.game_control_info?.privacy_policy ? (
              <div className="every">
                <p className="title">隐私权限</p>
                {game.game_control_info?.authority_info && (
                  <p className="font">
                    权限信息
                    <NavLink
                      className="img"
                      user={false}
                      to={`${sysUrl}/home/CompanyJurisdiction?gameId=${game.game_id}&maiyouGameId=${game.maiyou_gameid}&device=web`}
                      iframe={{ title: "权限信息" }}
                    >
                      <span className="btn">查看</span>
                    </NavLink>
                  </p>
                )}

                {game.game_control_info?.privacy_policy && (
                  <p className="font" style={{ marginLeft: "16px" }}>
                    隐私政策
                    <NavLink
                      className="img"
                      user={false}
                      to={`${sysUrl}/home/CompanyJurisdiction/web?gameId=${game.game_id}&maiyouGameId=${game.maiyou_gameid}&device=web`}
                      iframe={{ title: "隐私政策" }}
                    >
                      <span className="btn">查看</span>
                    </NavLink>
                  </p>
                )}
              </div>
            ) : null}
          </div>
          {manufacturerMore && (
            <div
              className={"more " + (manufacturerMored ? "ed" : "")}
              onClick={() => setManufacturerMored(!manufacturerMored)}
            >
              {manufacturerMored ? (
                <img
                  src="/resources/game/top.png"
                  alt=""
                  style={{ width: "20px" }}
                />
              ) : (
                <img
                  src="/resources/game/bottom.png"
                  alt=""
                  style={{ width: "20px" }}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="game-games">
        <div className="head">
          <div className="text">猜你喜欢</div>
          <div className="operate" onClick={() => recommend()}>
            <SyncOutlined className="icon" />
            换一批
          </div>
        </div>
        <div className="body">
          {game.recommend_info?.map((game) => (
            <Link
              key={game.game_id}
              to={"/game?id=" + game.game_id}
              className="item"
            >
              <div className="icon">
                <Image src={game.game_image.thumb} alt="" />
                {game.discount && game.discount < 1 && (
                  <div className="discount">
                    <span style={{ fontSize: "0.24rem", fontWeight: "bold" }}>
                      {parseFloat((game.discount * 10).toFixed(2))}
                    </span>
                    <span style={{ fontSize: "0.2rem" }}>折</span>
                  </div>
                )}
              </div>
              <div className="name">
                {game.game_name_main || game.game_name}
              </div>
              {game.game_name_branch && (
                <div className="branch">
                  <p>{game.game_name_branch}</p>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
      <NavLink to={"/service"} className="game-help">
        此游戏有问题？<span>联系客服</span>
      </NavLink>
    </div>
  );
};
