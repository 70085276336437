import KeepScroll from "../../../lib/KeepScroll";
import DownloadGame from "./DownloadGame";
import CloudGame from "./CloudGame";
import { useLocalStorageState, useRequest, useSize, useUpdate } from "ahooks";
import { Link, useHistory } from "react-router-dom";
import Image from "../../../lib/Image";
import { useRef, useState } from "react";
import general from "../../../general";
import _ from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import useActiveState from "../../../lib/useActiveState";
import dayjs from "dayjs";
import Dialog from "../../../lib/Dialog";
import Active from "../../../lib/Active";
import ReserveGame from "./ReserveGame";

SwiperCore.use([Pagination]);

// 我的游戏页面
export const LikeGame = () => {
  const history = useHistory();
  const { data: likeGames = [] } = useRequest(
    () => {
      return general.axios
        .post("/base/newindex/getHomeGameList")
        .then(({ data: resData }) => {
          console.log("我的游戏-----", resData);
          if (!resData.status.succeed) {
            return;
          }
          let games = [];
          for (const item of resData.data.list) {
            if (item.title === "精品好游") {
              games = [...games, ...item.recommendGameslist];
            }
            if (item.title === "热门游戏") {
              item.hotGameslist = _.shuffle(item.hotGameslist);
              games = [...games, ...item.hotGameslist.slice(0, 5)];
            }
          }
          return games;
        });
    },
    { cacheKey: "user/game/getHomeGameList" }
  );

  return (
    <>
      {likeGames.length ? (
        <>
          <div className="like-game">
            <div className="like-game-head">
              <div className="title">
                <img src="/resources/user/game/like-title.png" alt="" />
              </div>
              <div className="opt" onClick={() => history.push("/screen-game")}>
                更多游戏&gt;
              </div>
            </div>
            <div className="like-game-body">
              <Swiper autoplay={true} pagination={{ clickable: true }}>
                {_.chunk(likeGames, 3).map((games, index) => (
                  <SwiperSlide key={index}>
                    {games.map((game) => (
                      <Link
                        key={game.game_id}
                        to={"/game?id=" + game.game_id}
                        className="item"
                      >
                        <div className="icon">
                          <Image src={game.game_image.thumb} />
                        </div>
                        <div className="data">
                          <div className="name">
                            {game.game_name_main || game.game_name}
                          </div>
                          <div className="desc">
                            {game.game_name_branch ? (
                              <div className="branch">
                                {game.game_name_branch}
                              </div>
                            ) : null}
                            {game.game_classify_type}
                            &nbsp;·&nbsp;
                            {game.gama_size.ios_size}
                          </div>
                          <div className="info">
                            {game.game_desc?.split("+").map((item, index) => (
                              <div key={index} className="tag">
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const HotGame = () => {
  const update = useUpdate();
  const history = useHistory();
  const { data: hotGames = [] } = useRequest(() => {
    return general.axios.post("/reserveGames").then(({ data: resData }) => {
      if (!resData.status.succeed) {
        return;
      }
      return resData.data.game_list;
    });
  });

  const reserve = (game) => {
    general.axios
      .post(game.is_reserved ? "/cancelReserveGame" : "/reserveGame", {
        id: game.maiyou_gameid,
      })
      .then((res) => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
          return;
        }
        game.is_reserved = !game.is_reserved;
        update();
        Dialog.success(game.is_reserved ? "预约成功" : "取消预约");
      })
      .catch((err) => Dialog.error("reserveGame " + err.message));
  };

  return (
    <>
      {hotGames.length ? (
        <>
          <div className="hot-reserve">
            <div className="hot-reserve-head">
              <div className="title">
                <img src="/resources/user/game/reserve-title.png" alt="" />
              </div>
              <div
                className="opt"
                onClick={() => history.push("/?tab=new&type=1")}
              >
                更多游戏&gt;
              </div>
            </div>
            <div className="hot-reserve-body">
              {hotGames.slice(0, 4).map((game) => (
                <Link
                  key={game.game_id}
                  to={"/game?id=" + game.game_id}
                  className="item"
                >
                  <div className="icon">
                    <Image src={game.game_image.thumb} />
                  </div>
                  <div className="data">
                    <div className="name">
                      {game.game_name_main || game.game_name}
                    </div>
                    <div className="desc">
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                      {game.game_classify_type}
                      &nbsp;·&nbsp;
                      {game.gama_size.ios_size}
                    </div>
                    <div className="time">
                      {dayjs.unix(game.starting_time).format("MM-DD HH:mm")}
                      首发
                    </div>
                  </div>
                  <div className="opt">
                    <div
                      className={`btn ${game.is_reserved ? "active" : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        reserve(game);
                      }}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default (props) => {
  const update = useUpdate();
  const [config] = useActiveState("config");
  const tabs = [
    {
      id: "download",
      name: "游戏库",
      view: DownloadGame,
      extra: LikeGame,
    },
    {
      id: "cloud",
      name: "云玩",
      view: CloudGame,
      badge:
        config.udid_usable &&
        config.udid_usable != localStorage.getItem("udid_usable"),
      extra: LikeGame,
    },
    {
      id: "reserve",
      name: "预约",
      view: ReserveGame,
      extra: HotGame,
    },
  ];
  const [current, setCurrent] = useLocalStorageState("user_game_current", {
    defaultValue: 0,
  });
  const [swiper, setSwiper] = useState();

  const ref = useRef(null);
  const size = useSize(ref);

  return (
    <div className="user-game">
      <div className="ug-head">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`item ${index === current ? "active" : ""} ${
              tab.badge ? "badge" : ""
            }`}
            onClick={() => {
              setCurrent(index);
              swiper && swiper.slideTo(index);
            }}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="ug-body">
        <Swiper
          onSwiper={setSwiper}
          initialSlide={current}
          onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
        >
          {tabs.map((tab, index) => (
            <SwiperSlide key={index}>
              <div
                className={`ug-type ${index === current ? "active" : ""}`}
                style={{ paddingBottom: size?.height }}
              >
                <KeepScroll active={props.active && index === current} lazy>
                  <tab.view
                    active={props.active && index === current}
                    action={props.action}
                    update={update}
                  />
                </KeepScroll>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="ug-extra" ref={ref}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ug-extra-item  ${index === current ? "active" : ""}`}
            >
              <Active active={props.active && index === current}>
                <tab.extra
                  active={props.active && index === current}
                  action={props.action}
                />
              </Active>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
