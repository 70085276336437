import React, { useEffect, useState } from "react";
import general, { spm } from "../../../general";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useScroll, useUpdateEffect } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import dayjs from "dayjs";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/downloadedGames", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setGames((games) =>
          parms.page === 1 || !parms.page
            ? result.game_list
            : [...games, ...result.game_list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useUpdateEffect(() => {
    if (props.active && (!init || props.action === "POP")) {
      load();
    }
  }, [props.action]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <>
      <div className="ug-main">
        {games.length ? (
          <>
            <div className="ug-tips">若已下载，可自行去桌面畅玩游戏</div>
            <div className="ug-game">
              {games.map((game) => (
                <Link
                  key={game.game_id}
                  to={"/game?id=" + game.game_id}
                  className="item"
                  onClick={() =>
                    spm(`user.downloadGame.${game.maiyou_gameid}.download`)
                  }
                >
                  <div className="body">
                    <div className="icon">
                      <Image src={game.game_image.thumb} />
                    </div>
                    <div className="data">
                      <div className="name">
                        {game.game_name_main || game.game_name}
                      </div>
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                      <div className="desc">
                        {dayjs.unix(game.downloadedTime).format("MM-DD HH:mm")}{" "}
                        下载过
                      </div>
                    </div>
                    <div className="opt">
                      <object className="btn">
                        <Link to={`/game?id=${game.game_id}&download=true`}>
                          下载
                        </Link>
                      </object>
                    </div>
                  </div>
                </Link>
              ))}
              {games.length > 0 && !more ? (
                <div className="view-end">别撩啦！已经到底啦~</div>
              ) : null}
            </div>
          </>
        ) : null}
        {init && !games.length ? (
          <>
            <div className="ug-empty">
              <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
              <p>
                您还没有下载过游戏，看看下面的游戏
                <br />
                有没有你喜欢的吧
              </p>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
