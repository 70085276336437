import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMount, useReactive } from "ahooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import URI from "urijs";
import { RightOutlined } from "@ant-design/icons";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import useRequest from "../../lib/useRequest";
import useActiveState from "../../lib/useActiveState";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();

  const [app] = useActiveState("app");
  const data = useReactive({
    faqGroups: [],
    weixinUrl: "https://wpa1.qq.com/IBW41dK4?_type=wpa&qidian=true",
    weixinWindowUrl: "weixin://",
    wechatUrl: "https://wpa1.qq.com/qNWn4ufQ?_type=wpa&qidian=true",
    weixinUrlLoad: false,
    weixinUrlClick: false,
    customer_service_config: null,
  });
  const { data: _data, loading } = useRequest(
    () => {
      Dialog.loading();
      return general.axios
        .post("/base/kefu/index")
        .finally(() => Dialog.close())
        .then(({ data: resData }) => {
          if (!resData.status.succeed) {
            Dialog.error(resData.status.error_desc);
            return false;
          }
          let result = resData.data;
          general.faqGroups = result.faq;
          return {
            qq: result.kefu_info.qq,
            time: result.kefu_info.online_time,
            weixin: result.kefu_info.wechat_public_number_title,
            tel: result.complaint_tel,
            faqGroups: result.faq,
            customer_service_config: result.customer_service_config,
          };
        })
        .catch((error) => Dialog.error(error.message));
    },
    { cacheKey: "service" }
  );
  useEffect(() => {
    if (_data) {
      for (const [key, val] of Object.entries(_data)) {
        data[key] = val;
      }
    }
  }, [loading]);

  const [faq, setFaq] = useState(null);

  const x = () => {
    general.axios.get("/base/newindex/csButtonClick");
  };

  useMount(() => {
    // 获取微信客服链接
    general.axios
      .get("/getWeixinServiceUrl")
      .then(({ data: resData }) => {
        if (resData?.data?.url) {
          data.weixinUrl = resData.data.url;
        }
      })
      .finally(() => {
        data.weixinUrlLoad = true;
        if (data.weixinUrlClick) {
          openWeixinUrl();
        }
      });
  });

  useEffect(() => {
    if (data.weixinUrlClick) {
      if (!data.weixinUrlLoad) {
        Dialog.loading("正在拉起微信 请等待");
      } else {
        Dialog.close();
      }
    }
  }, [data.weixinUrlLoad, data.weixinUrlClick]);

  const openWeixinUrl = () => {
    if (!data.weixinUrlLoad) {
      data.weixinUrlClick = true;
      return;
    }
    if (navigator.userAgent == "app") {
      window.location.href = URI("app://browser")
        .addQuery("url", data.weixinUrl)
        .toString();
    } else {
      window.location.href = data.weixinUrl;
    }
  };

  const openWeixinWindowUrl = () => {
    if (!data.weixinUrlLoad) {
      data.weixinUrlClick = true;
      return;
    }
    if (navigator.userAgent == "app") {
      window.location.href = URI("app://browser")
        .addQuery("url", data.weixinWindowUrl)
        .toString();
    } else {
      window.location.href = data.weixinWindowUrl;
    }
  };

  return (
    <>
      <div className="service-content">
        <img
          className="service-top-img"
          src="/resources/service/banner.png"
          alt=""
        />

        <div className="service-index">
          <div className="service-contact">
            {!data.customer_service_config ||
            (data.customer_service_config &&
              data.customer_service_config.status === "1" &&
              data.customer_service_config?.connect_type === "0") ? (
              <div
                className="item"
                onClick={() => {
                  x();
                  openWeixinUrl();
                }}
              >
                <div
                  className="icon"
                  style={{
                    backgroundImage: "url(/resources/service/wechat.png)",
                  }}
                />
                <div className="line"></div>
                <div className="data">
                  <div className="name">微信在线客服</div>
                  <div className="value">咨询时间：{data.time}</div>
                </div>
                <div className="opt">
                  <RightOutlined className="more" />
                </div>
              </div>
            ) : (
              <>
                {data.customer_service_config?.status === "1" && (
                  <div className="service-contact">
                    {!general.cloud ? (
                      <>
                        <div
                          className="item"
                          onClick={() => {
                            x();

                            if (
                              data?.customer_service_config?.connect_type ===
                              "3"
                            ) {
                              data.weixinUrl =
                                data.customer_service_config?.content;
                              openWeixinUrl();
                            } else {
                              history.push(
                                `/service/detail?type=${
                                  data.customer_service_config?.connect_type
                                }&content=${encodeURIComponent(
                                  data.customer_service_config?.content
                                )}`
                              );
                            }
                          }}
                        >
                          <div
                            className="icon"
                            style={{
                              backgroundImage:
                                "url(/resources/service/wechat.png)",
                            }}
                          />
                          <div className="line"></div>
                          <div className="data">
                            <div className="name">微信在线客服</div>
                            <div className="value">
                              {data.customer_service_config?.online_time
                                ? `咨询时间：${data.customer_service_config.online_time}`
                                : "你我沟通更方便"}
                            </div>
                          </div>
                          <div className="opt">
                            <RightOutlined className="more" />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              </>
            )}

            {!data.customer_service_config ||
            (data.customer_service_config &&
              data.customer_service_config.type === "1" &&
              data.customer_service_config.status === "1" &&
              data.customer_service_config?.wxofficial_type === "0") ? (
              <>
                {app.brand !== "zc" && app.brand !== "fw" && (
                  <CopyToClipboard
                    text={data.weixin}
                    onCopy={() => {
                      Dialog.info("已复制公众号", () => openWeixinWindowUrl());
                    }}
                  >
                    <div className="item" onClick={() => x()}>
                      <div
                        className="icon"
                        style={{
                          backgroundImage:
                            "url(/resources/service/Official-Accounts.png)",
                        }}
                      />
                      <div className="line"></div>
                      <div className="data">
                        <div className="name">微信公众号</div>
                        <div className="value">
                          关注“{data.weixin}”更多消息不错过
                        </div>
                      </div>
                      <div className="opt">
                        <RightOutlined className="more" />
                      </div>
                    </div>
                  </CopyToClipboard>
                )}
              </>
            ) : (
              <>
                {data.customer_service_config.status === "1" &&
                  data.customer_service_config.official_status === "1" && (
                    <CopyToClipboard
                      text={data.customer_service_config?.official_name}
                      onCopy={() => {
                        Dialog.info("已复制公众号", () =>
                          openWeixinWindowUrl()
                        );
                      }}
                    >
                      <div className="item" onClick={() => x()}>
                        <div
                          className="icon"
                          style={{
                            backgroundImage:
                              "url(/resources/service/Official-Accounts.png)",
                          }}
                        />
                        <div className="line"></div>
                        <div className="data">
                          <div className="name">微信公众号</div>
                          <div className="value">
                            关注“{data.customer_service_config?.official_name}
                            ”更多消息不错过
                          </div>
                        </div>
                        <div className="opt">
                          <RightOutlined className="more" />
                        </div>
                      </div>
                    </CopyToClipboard>
                  )}
              </>
            )}
          </div>

          {/* {!data.customer_service_config ||
          (data.customer_service_config &&
            data.customer_service_config.status === "1" &&
            data.customer_service_config?.connect_type === "0") ? (
            <div className="service-contact">
              {!general.cloud ? (
                <>
                  <div
                    className="item"
                    onClick={() => {
                      x();
                      openWeixinUrl();
                    }}
                  >
                    <div
                      className="icon"
                      style={{
                        backgroundImage: "url(/resources/service/wechat.png)",
                      }}
                    />
                    <div className="line"></div>
                    <div className="data">
                      <div className="name">微信在线客服</div>
                      <div className="value">咨询时间：{data.time}</div>
                    </div>
                    <div className="opt">
                      <RightOutlined className="more" />
                    </div>
                  </div>
                </>
              ) : null}

              {app.brand !== "zc" && app.brand !== "fw" && (
                <CopyToClipboard
                  text={data.weixin}
                  onCopy={() => {
                    Dialog.info("已复制公众号", () => openWeixinWindowUrl());
                  }}
                >
                  <div className="item" onClick={() => x()}>
                    <div
                      className="icon"
                      style={{
                        backgroundImage:
                          "url(/resources/service/Official-Accounts.png)",
                      }}
                    />
                    <div className="line"></div>
                    <div className="data">
                      <div className="name">微信公众号</div>
                      <div className="value">
                        关注“{data.weixin}”更多消息不错过
                      </div>
                    </div>
                    <div className="opt">
                      <RightOutlined className="more" />
                    </div>
                  </div>
                </CopyToClipboard>
              )}
            </div>
          ) : (
            <>
              {data.customer_service_config?.status === "1" && (
                <div className="service-contact">
                  {!general.cloud ? (
                    <>
                      <div
                        className="item"
                        onClick={() => {
                          x();

                          if (
                            data?.customer_service_config?.connect_type === "3"
                          ) {
                            data.weixinUrl =
                              data.customer_service_config?.content;
                            openWeixinUrl();
                          } else {
                            history.push(
                              `/service/detail?type=${
                                data.customer_service_config?.connect_type
                              }&content=${encodeURIComponent(
                                data.customer_service_config?.content
                              )}`
                            );
                          }
                        }}
                      >
                        <div
                          className="icon"
                          style={{
                            backgroundImage:
                              "url(/resources/service/wechat.png)",
                          }}
                        />
                        <div className="line"></div>
                        <div className="data">
                          <div className="name">微信在线客服</div>
                          <div className="value">你我沟通更方便</div>
                        </div>
                        <div className="opt">
                          <RightOutlined className="more" />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </>
          )} */}

          <div className="service-bt">常见问题</div>
          <div className="service-faq">
            {data.faqGroups.map((faqGroup, groupIndex) => (
              <dl
                key={groupIndex}
                className={faq === groupIndex ? "active" : ""}
              >
                <dt
                  onClick={() =>
                    faqGroup.id != "tel" &&
                    setFaq(groupIndex === faq ? null : groupIndex)
                  }
                >
                  <div className="full">
                    <div
                      className="icon"
                      style={{
                        backgroundImage:
                          "url(/resources/service/" + faqGroup.text + ".png)",
                      }}
                    />
                    <div className="text">{faqGroup.text}</div>
                    <RightOutlined className="more" />
                  </div>
                </dt>
                <dd>
                  {faqGroup?.items?.map((faq, questionIndex) => (
                    <Link
                      key={questionIndex}
                      to={
                        "/faq?group=" +
                        groupIndex +
                        "&question=" +
                        questionIndex
                      }
                    >
                      {faq.q}
                    </Link>
                  ))}
                </dd>
              </dl>
            ))}
            <dl>
              <dt>
                <div className="full">
                  <div
                    className="icon"
                    style={{
                      backgroundImage: "url(/resources/service/tel.png)",
                    }}
                  />
                  <div className="text">投诉电话</div>
                  <div className="tel-info">
                    {data.tel}
                    <p className="tel-info-tips">(仅处理投诉问题)</p>
                  </div>
                </div>
                <a
                  href={data.tel ? "tel://" + data.tel : ""}
                  className="tel-btn"
                >
                  拨打
                </a>
              </dt>
            </dl>
          </div>
        </div>
      </div>

      {/* <CopyToClipboard
        text={data.qq}
        onCopy={() => {
          Dialog.info(
            "已复制QQ号",
            () =>
              (window.location.href =
                "mqqwpa://im/chat?chat_type=wpa&uin=" +
                data.qq +
                "&version=1&src_type=web&web_src=qq.com")
          );
        }}
      >
        <div className="item" onClick={() => x()}>
          <div className="container">
            <div className="data">
              <div className="label">
                <div
                  className="icon"
                  style={{
                    backgroundImage: "url(/resources/service/qq.png)",
                  }}
                />
                <div className="name">QQ在线客服</div>
              </div>
              <div className="value">
                <div className="value">{data.qq}</div>
              </div>
            </div>
            <div className="opt">
              <RightOutlined className="more" />
            </div>
          </div>
        </div>
      </CopyToClipboard>
      <NavLink
        to={`${sysUrl}/home/bindaccount`}
        iframe={{ title: "绑定微信" }}
        className="item"
      >
        <div className="container">
          <div className="data">
            <div className="label">
              <div
                className="icon"
                style={{
                  backgroundImage: "url(/resources/service/weixin.png)",
                }}
              />
              <div className="name">微信提醒</div>
            </div>
            <div className="value">
              <div className="value">重要消息不错过</div>
            </div>
          </div>
          <div className="opt">
            <RightOutlined className="more" />
          </div>
        </div>
      </NavLink> */}
    </>
  );
};
