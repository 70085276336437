import React, { useEffect, useState } from "react";
import general, { spm } from "../../../general";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useLocalStorageState, useScroll, useUpdateEffect } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import dayjs from "dayjs";
import useActiveState from "../../../lib/useActiveState";
import { useHistory } from "react-router-dom";
import qs from "qs";

export default (props) => {
  const history = useHistory();
  const [config] = useActiveState("config");
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/cloudPlayedGames", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setGames((games) =>
          parms.page === 1 || !parms.page
            ? result.game_list
            : [...games, ...result.game_list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useUpdateEffect(() => {
    if (props.active && (!init || props.action === "POP")) {
      load();
    }
  }, [props.action]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  useEffect(() => {
    if (config.udid_usable !== null) {
      localStorage.setItem("udid_usable", config.udid_usable ? "1" : "");
      props.update();
    }
  }, [config.udid_usable]);

  const [tipsed, setTipsed] = useLocalStorageState(
    "user_cloud_game_tipsed" + dayjs().format("YYYYMM")
  );

  return (
    <>
      <div className="ug-main">
        {games.length ? (
          <>
            <div className="ug-tips">若已添加至桌面，可自行去桌面畅玩游戏</div>
            <div className="ug-game">
              {games.map((game) => (
                <div key={game.game_id} className="item">
                  <Link to={"/game?id=" + game.game_id} className="body">
                    <div className="icon">
                      <Image src={game.game_image.thumb} />
                    </div>
                    <div className="data">
                      <div
                        className="name"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {game.game_name_main || game.game_name}

                        {(game?.cloud_support_type !== "sq" ||
                          game?.cloud_disabled !== 0) && (
                          <div
                            style={{
                              fontSize: "0.2rem",
                              color: "#999",
                              fontWeight: "normal",
                            }}
                          >
                            此游云玩已关闭
                          </div>
                        )}
                      </div>
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                      {game.cloudPlayedTime && (
                        <div className="desc">
                          {dayjs
                            .unix(game.cloudPlayedTime)
                            .format("MM-DD HH:mm")}{" "}
                          玩过
                        </div>
                      )}
                    </div>
                  </Link>
                  <div className="foot">
                    {game?.cloud_support_type === "sq" &&
                      game?.cloud_disabled === 0 && (
                        <Link
                          className="btn"
                          to={`/game?id=${game.game_id}&cloudType=start`}
                          onClick={() =>
                            spm(`user.cloudGame.${game.maiyou_gameid}.cloud`)
                          }
                        >
                          开始游戏
                        </Link>
                      )}

                    {game?.cloud_support_type === "sq" &&
                    game?.cloud_disabled === 0 ? (
                      <>
                        <div
                          className="btn"
                          onClick={() => {
                            history.push(
                              `/game/cloud?${qs.stringify({
                                id: game.game_id,
                                agent: localStorage.getItem("agent") || "x7z1",
                                user: localStorage.getItem("user_id"),
                                username: localStorage.getItem("username"),
                                token: localStorage.getItem("token"),
                                spm: "normal",
                                assign: null,
                                auto: "desktop",
                              })}`
                            );
                          }}
                        >
                          添加至桌面
                        </div>
                      </>
                    ) : (
                      <>
                        <Link
                          className="btn"
                          to={`/game?id=${game.game_id}&download=true`}
                          onClick={() => {
                            setTipsed(true);
                            spm(
                              `user.cloudGame.${game.maiyou_gameid}.download`
                            );
                          }}
                        >
                          下载
                          {!tipsed ? (
                            <div className="tips">下载客户端更稳定</div>
                          ) : null}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              ))}
              {games.length > 0 && !more ? (
                <div className="view-end">别撩啦！已经没有更多数据~</div>
              ) : null}
            </div>
          </>
        ) : null}
        {init && !games.length ? (
          <>
            <div className="ug-empty">
              <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
              <p>
                您还没有云玩过游戏，看看下面的游戏
                <br />
                有没有你喜欢的吧
              </p>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
