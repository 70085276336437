import { useEffect, useState } from "react";
import Dialog from "../../../lib/Dialog";
import general from "../../../general";

export default (props) => {
  let [questionInfo, setQuestionInfo] = useState();

  useEffect(() => {
    if (!props?.query?.id) {
      Dialog.error("缺少id，请退出重新进入");
      return;
    }

    Dialog.loading();
    general.axios
      .post("/base/game/iosProblemDetail", { id: props?.query?.id })
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        console.log("问题详情----", data);

        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        props.setTitle(data?.title);

        questionInfo = data;
        setQuestionInfo(data);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  }, []);

  return (
    <>
      <div className="question-detail">
        <div
          dangerouslySetInnerHTML={{
            __html: questionInfo?.content,
          }}
        />
      </div>
    </>
  );
};
